import styled from '@emotion/styled'
import { Colors } from '@/constants/styles/color'

const StyledDetailContainer = styled.div`
  width: 100%;
  min-height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${Colors.VERY_LIGHT_GRAY};
  padding: 16px 0;
`

const ManagementDetailTitle = styled.div`
  color: ${Colors.PRIMARY_GRAY};
  font-size: 12px;
  font-weight: 500;
  line-height: 13px;
`

const ManagementDetailData = styled.div<{ isError?: boolean; fontSize?: number }>`
  color: ${props => (props.isError ? Colors.ERROR : Colors.PRIMARY_GRAY)};
  font-size: ${props => (props.fontSize ? props.fontSize + 'px' : '12px')};
  font-weight: 500;
  line-height: 14px;
`

const ManagementAmountContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const ManagementAmountTotal = styled.div`
  display: grid;
  align-items: center;
  column-gap: 16px;
  grid-template-columns: 158px auto;
  justify-items: end;
  margin-bottom: 12px;
`

const ManagementAmountDetail = styled.div`
  display: grid;
  align-items: center;
  column-gap: 16px;
  grid-template-columns: 158px auto;
  justify-items: end;
  margin-bottom: 8px;
`

const ManagementTotalAmount = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  color: ${Colors.PRIMARY_GRAY};
  span {
    font-size: 10px;
    font-weight: 400;
    line-height: 10px;
  }
`

const ManagementAmount = styled.div<{ isGray?: boolean }>`
  color: ${props => (props.isGray ? Colors.GRAY : Colors.PRIMARY_GRAY)};
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
`

const ManagementAmountVal = styled.div<{ isRed?: boolean }>`
  color: ${props => (props.isRed ? Colors.RED : Colors.PRIMARY_GRAY)};
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
`

const ManagementErrFee = styled.div`
  color: ${Colors.ERROR};
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
`

const FieldAppIcon = styled.img`
  object-fit: cover;
  width: 40px;
  height: 40px;
`

const AppName = styled.div`
  color: ${Colors.PRIMARY_GRAY};
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: right;
`

const AppWapper = styled.div`
  display: grid;
  grid-template-columns: calc(100% - 48px) 40px;
  align-items: center;
  column-gap: 12px;
  width: 100%;
  margin-bottom: 16px;
  :last-child {
    margin-bottom: 0;
  }
`

const AppItemNameWapper = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
`

const SellerAppName = styled.div`
  color: ${Colors.GRAY};
  font-size: 11px;
  font-weight: 500;
  line-height: 13px;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: right;
  margin-bottom: 8px;
`

export {
  StyledDetailContainer,
  ManagementDetailTitle,
  ManagementDetailData,
  ManagementAmountContainer,
  ManagementAmountTotal,
  ManagementAmountDetail,
  ManagementTotalAmount,
  ManagementAmount,
  ManagementAmountVal,
  ManagementErrFee,
  FieldAppIcon,
  AppName,
  AppWapper,
  AppItemNameWapper,
  SellerAppName,
}
