import React from 'react'
import getConfig from 'next/config'
import Router from 'next/router'
import styled from '@emotion/styled'
import { Colors } from '@/constants/styles/color'
import ArrowRight from '@/assets/img/icons/Arrow_Right.svg'
import CloseIcon from '@/assets/img/icons/Close_M.svg'
import HalfModal from '@/components/atoms/Dialog/HalfModal'
import { useFanmeBackendGet, useUser } from '@/libs/fanme_backend'

const StyledHeaderTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  color: ${Colors.PRIMARY_GRAY};
`

const StyledCloseIcon = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 16px;
`

const StyledMainContainer = styled.div`
  padding: 16px;
`

const StyledMenuItem = styled.div`
  min-height: 56px;
  border-bottom: solid 1px ${Colors.VERY_LIGHT_GRAY};
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  position: relative;
  row-gap: 8px;
`

const StyledMenuTitle = styled.div`
  font-size: 11px;
  font-weight: 500;
  line-height: 11px;
  color: ${Colors.PRIMARY_GRAY};
  min-height: 16px;
  display: flex;
  align-items: center;
`

const StyledMenuValue = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  color: ${Colors.PRIMARY_GRAY};
  min-height: 24px;
  display: flex;
  align-items: center;
`

const StyledMenuPassword = styled.div`
  font-size: 8px;
  font-weight: 400;
  line-height: 10px;
  letter-spacing: 2px;
  color: ${Colors.PRIMARY_GRAY};
  min-height: 24px;
  display: flex;
  align-items: center;
`

interface Props {
  visible: boolean
  onClose: () => void
}

const { publicRuntimeConfig } = getConfig()

const AuthMenuPopup = ({ visible, onClose }: Props) => {
  const { data: loginData } = useFanmeBackendGet<any>(`/auth/oauth2/user/info`, { auth: true })
  const { data: profile } = useUser()

  const onModifyEmail = () => {
    const redirectUrl = `${publicRuntimeConfig.FRONT_URL}/@${profile?.account_identity}?update=email`
    const encoded = encodeURIComponent(redirectUrl)
    Router.push(`${publicRuntimeConfig.AUTH_DOMAIN}/user/email/change?redirect_uri=${encoded}`)
  }

  const renderHeader = () => (
    <>
      <StyledHeaderTitle>メールアドレス</StyledHeaderTitle>
      <StyledCloseIcon>
        <CloseIcon width={14} height={14} onClick={onClose} />
      </StyledCloseIcon>
    </>
  )

  const renderContent = () => (
    <StyledMainContainer>
      <StyledMenuItem onClick={onModifyEmail}>
        <StyledMenuTitle>メールアドレス</StyledMenuTitle>
        <StyledMenuValue>
          {loginData && !loginData.external_auth
            ? loginData.email
            : loginData && !loginData.email
            ? '未設定'
            : loginData && loginData.email}
        </StyledMenuValue>
        <ArrowRight
          width={16}
          height={16}
          style={{ position: 'absolute', right: 0, top: 0, bottom: 0, margin: 'auto' }}
        />
      </StyledMenuItem>
    </StyledMainContainer>
  )

  if (visible === false) return <></>
  if (!loginData || !profile) return <></>
  return (
    <HalfModal
      header={renderHeader()}
      content={renderContent()}
      onClose={onClose}
      zIndex={10001}
      margin="auto"
    />
  )
}

export default AuthMenuPopup
