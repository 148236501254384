import React from 'react'
import dayjs from 'dayjs'
import { Colors } from '@/constants/styles/color'
import type { NOTIFICATION_TYPES } from '@/constants'
import NotificationIconGray from '@/assets/img/icons/Notification.svg'
import FullModal from '@/components/atoms/Dialog/FullModal'
import FullModalHeader from '@/components/molecules/Header/FullModalHeader'
import NotificationListItem from '@/components/molecules/MyMenu/NotificationListItem'
import { usePaginatedStream } from '@/utils/hooks/pagination'
import { useFanmeBackendGet } from '@/libs/fanme_backend'
import FanmeLoading from '@/components/atoms/FanmeLoading'
import { getTimeDifferenceText } from '@/utils/date'
import { StyledCaption, NoItemContainer } from './index.style'

export type Notification = {
  title: string
  content: string
  createdAt: string
  metadata: string
  isNew: boolean
  type: NOTIFICATION_TYPES
  diff?: string
  navigationUrl?: string
  iconUrl?: string
}

interface Props {
  accountId: string
  visible: boolean
  onClose: () => void
}

const NotificationList = ({ accountId, visible, onClose }: Props) => {
  const timeDivider = [0, 7 * 24 * 3600, 30 * 24 * 3600]
  const timeDividerLabel = ['新着', '1週間前', '1ヶ月前']
  let currentTimeIndex = -1
  const { component } = usePaginatedStream({
    useFetcher: token => {
      return useFanmeBackendGet<any>(
        `/payment/internal/notification/stream${token ? `?cursor=${token}` : ''}`,
      )
    },
    builder: (items, vendors, index) => {
      items = items.map((item: any) => ({
        ...item,
        diff: getTimeDifferenceText(dayjs.utc(item.createdAt)),
      }))

      if (index === 0) {
        // 最初のページの場合はリセット
        currentTimeIndex = -1
        if (items.length === 0)
          return (
            <NoItemContainer>
              <NotificationIconGray width={64} height={64} fill={Colors.LIGHT_GRAY} />
              <div>通知はありません</div>
            </NoItemContainer>
          )
      }
      return items.map((item: any, index: number) => {
        const key = `item-${item.id}-${index}`
        if (currentTimeIndex < 0) {
          currentTimeIndex = 1
          if (item.isNew) {
            // 先頭が最新の場合新着を表示
            return (
              <React.Fragment key={key}>
                <StyledCaption>{timeDividerLabel[0]}</StyledCaption>
                <NotificationListItem item={item} />
              </React.Fragment>
            )
          }
        }
        if (
          !item.isNew &&
          timeDivider[currentTimeIndex] &&
          dayjs().diff(dayjs.utc(item.createdAt), 'second') >= timeDivider[currentTimeIndex]
        ) {
          // 各時間帯の区切りを超えた場合
          currentTimeIndex++
          return (
            <React.Fragment key={key}>
              <StyledCaption>{timeDividerLabel[currentTimeIndex - 1]}</StyledCaption>
              <NotificationListItem item={item} />
            </React.Fragment>
          )
        }
        return (
          <React.Fragment key={key}>
            <NotificationListItem item={item} />
          </React.Fragment>
        )
      })
    },
    loaderBuilder: () => <FanmeLoading height="calc(100vh - 56px)" top="56px" />,
  })

  const renderHeader = () => <FullModalHeader title="通知" onBack={onClose} />

  return (
    <FullModal
      header={renderHeader()}
      content={component}
      visible={visible}
      zIndex={10000}
      bodyPadding="16px"
      bodyHeight="calc(100vh - 56px)"
    />
  )
}

export default NotificationList
