import styled from '@emotion/styled'
import { Colors } from '@/constants/styles/color'

const MoneyManageDescription = styled.div`
  height: 24px;
  padding-left: 12px;
  border-left: 1px solid ${Colors.PRIMARY_GRAY};
  color: ${Colors.PRIMARY_GRAY};
  font-size: 13px;
  font-weight: 500;
  line-height: 13px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`

const MoneyManageDescriptionRight = styled.div`
  font-size: 11px;
  font-weight: 400;
  line-height: 11px;
  text-align: right;
  color: ${Colors.GRAY};
  margin: 0 16px;
  padding: 8px 0 16px;
  border-top: 1px solid ${Colors.VERY_LIGHT_GRAY};
  span {
    margin-left: 8px;
  }
`

const MoneyManageNotice = styled.div`
  font-size: 10px;
  font-weight: 400;
  line-height: 160%;
  color: ${Colors.GRAY};
  text-align: left;
  margin-top: 8px;
`

const MoneyManageCardTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  color: ${Colors.PRIMARY_GRAY};
`

const MoneyManageCardPrice = styled.div`
  font-size: 22px;
  font-weight: 600;
  line-height: 22px;
  color: ${Colors.PRIMARY_GRAY};
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  gap: 12px;
`

const MoneyManageCardTitleContainer = styled.div<{ line: number }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 64px;
  border-top: ${props => (props.line > 0 ? `1px solid ${Colors.VERY_LIGHT_GRAY}` : '')};
  padding: 16px 0;
  margin: 0 16px;
`

const MoneyManageCardBorder = styled.div`
  padding-bottom: 16px;
`

const MoneyIdentification = styled.a`
  color: ${Colors.ORANGE};
`

const MoneyIdentificationMsg = styled.span<{ color: string }>`
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  color: ${props => props.color || Colors.PRIMARY_GRAY};
`

const SalesMoneyText = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 13px;
  color: ${Colors.PRIMARY_GRAY};
  padding-bottom: 16px;
`
const SalesMoney = styled.div<{ visible?: boolean }>`
  font-weight: 600;
  font-size: 30px;
  line-height: 30px;
  color: ${props => (props.visible ? Colors.PRIMARY_GRAY : Colors.PALE_GRAY)};
  padding-bottom: 16px;
`

const ProvisionalSalesText = styled.div`
  color: ${Colors.PRIMARY_GRAY};
  font-weight: 500;
  font-size: 13px;
  line-height: 13px;
  min-height: 24px;
  padding-bottom: 8px;
  align-items: center;
  justify-content: center;
`

const ProvisionalHelperContainer = styled.div<{ isLeft: boolean }>`
  align-items: start;
  margin-left: ${({ isLeft }) => (isLeft ? '0px' : 'calc(50% - 9 * 13px)')};
  justify-content: center;
  padding-bottom: 16px;
`

const ProvisionalHelperText = styled.div`
  color: ${Colors.ORANGE};
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
  text-align: left;
  padding-bottom: 8px;
`

const NotProvisionalSalesData = styled.div`
  width: 100%;
  min-height: 64px;
  color: ${Colors.LIGHT_GRAY};
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const TransferFeeText = styled.div`
  color: ${Colors.GRAY};
  font-size: 11px;
  font-weight: 400;
  line-height: 11px;
  span {
    margin-left: 8px;
  }
`

const ProvisionalSalesResultContainer = styled.div`
  padding: 0 16px 16px;
`

export {
  MoneyManageDescription,
  MoneyManageDescriptionRight,
  MoneyManageNotice,
  MoneyManageCardTitle,
  MoneyManageCardPrice,
  MoneyManageCardTitleContainer,
  MoneyManageCardBorder,
  MoneyIdentification,
  MoneyIdentificationMsg,
  SalesMoneyText,
  SalesMoney,
  ProvisionalSalesText,
  ProvisionalHelperContainer,
  ProvisionalHelperText,
  NotProvisionalSalesData,
  TransferFeeText,
  ProvisionalSalesResultContainer,
}
