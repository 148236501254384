import styled from '@emotion/styled'
import { Colors } from '@/constants/styles/color'

export const StyledDialogText = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 12px;
  text-align: center;
  margin-bottom: 24px;
`

const StyledDialogBody = styled.div`
  padding: 24px 24px 96px;
  height: calc(100% - 47px);
  overflow-y: scroll;
  color: ${Colors.BLACK};
`

const StyledDialogBodyItem = styled.div`
  width: 100%;
  padding: 16px 0;
  align-items: center;
  .item-value {
    display: flex;
    align-items: center;
    justify-content: right;
    gap: 8px;
    margin-left: 16px;
    .card-info {
      display: flex;
      width: 100%;
      .detail {
        display: grid;
        align-items: center;
        margin-left: 12px;
        .numbers {
          color: ${Colors.BLACK};
          font-weight: 500;
          font-size: 12px;
          line-height: 12px;
        }
        .date {
          font-weight: 400;
          font-size: 11px;
          line-height: 11px;
          color: ${Colors.GRAY};
        }
        .title {
          color: ${Colors.BLACK};
          font-weight: 500;
          font-size: 12px;
          line-height: 12px;
        }
      }
    }
  }
`

const StyledAddCardButton = styled.button`
  background: ${props => (props.disabled ? Colors.PALE_GRAY : Colors.BLACK)};
  border: 1px solid ${props => (props.disabled ? Colors.PALE_GRAY : Colors.BLACK)};
  border-radius: 24px;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Colors.WHITE};
  height: 48px;
  width: 100%;
  max-width: 328px;
  margin: 24px auto 40px;
  position: relative;
  img {
    position: absolute;
    left: 16px;
  }
`

const StyledFormInput = styled.div<{ isExistErrMsg: boolean }>`
  div {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  input {
    width: 100%;
    height: 48px;
    margin-top: 8px;
    margin-bottom: ${props => (props.isExistErrMsg ? '0' : '16px')};
    border-radius: 8px;
    border: 0px;
    background: ${Colors.ULTRA_LIGHT_GRAY};
    font-size: 13px;
    font-weight: 400;
    line-height: 13px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    &::placeholder {
      color: ${Colors.LIGHT_GRAY};
      font-size: 13px;
      font-weight: 400;
      line-height: 13px;
    }
  }
`

const StyledFormHalfInput = styled.div<{ isExistErrMsg: boolean }>`
  div {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  input {
    width: 50%;
    height: 48px;
    margin-top: 8px;
    margin-bottom: ${props => (props.isExistErrMsg ? '0' : '16px')};
    border-radius: 8px;
    border: 0px;
    background: ${Colors.ULTRA_LIGHT_GRAY};
    font-size: 13px;
    font-weight: 400;
    line-height: 13px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    &::placeholder {
      color: ${Colors.LIGHT_GRAY};
      font-size: 13px;
      font-weight: 400;
      line-height: 13px;
    }
  }
`

const StyledFormSelect = styled.div`
  div {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`

const StyledPostalCodeButton = styled.button`
  background: ${props => (props.disabled ? Colors.PALE_GRAY : Colors.BLACK)};
  border: 1px solid ${props => (props.disabled ? Colors.PALE_GRAY : Colors.BLACK)};
  border-radius: 20px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Colors.WHITE};
  height: 32px;
  min-width: 64px;
  position: relative;
  img {
    position: absolute;
    left: 16px;
  }
`

const StyledErrMsg = styled.div`
  width: 184px;
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
  display: flex;
  align-items: right;
  color: ${Colors.RED};
  margin: 8px 0;
  text-align: left;
`

const StyledAny = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
  color: ${Colors.GRAY};
  padding: 0px 4px;
  margin-left: 4px;
  margin-right: 8px;
`

const StyledForm = styled.div`
  margin: 0 0 16px;
`

const StyledTitle = styled.div`
  font-size: 11px;
  font-weight: 500;
  line-height: 11px;
`

const StyledRequired = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
  color: ${Colors.ORANGE};
  padding: 0px 4px;
  margin-left: 4px;
  margin-right: 8px;
`

const StyledHighlightDescription = styled.div`
  font-weight: 400;
  font-size: 10px;
  color: ${Colors.DARK_RED};
`

const StyledFormItem = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`

export {
  StyledDialogBody,
  StyledDialogBodyItem,
  StyledAddCardButton,
  StyledFormInput,
  StyledFormHalfInput,
  StyledErrMsg,
  StyledAny,
  StyledForm,
  StyledTitle,
  StyledRequired,
  StyledFormItem,
  StyledFormSelect,
  StyledPostalCodeButton,
  StyledHighlightDescription,
}
