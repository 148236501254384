import React from 'react'
import VisaIcon from '@/assets/img/payment/CC_Visa.svg'
import MasterIcon from '@/assets/img/payment/CC_Master.svg'
import JcbIcon from '@/assets/img/payment/CC_JCB.svg'
import AmericanExpressIcon from '@/assets/img/payment/CC_AmericanExpress.svg'
import DinersIcon from '@/assets/img/payment/CC_Diners.svg'

/**
 * カード会社ごとの表示名・アイコン
 */
export const cardTypeData: any = {
  VISA: {
    name: 'Visa',
    icon: <VisaIcon />,
  },
  MASTER: {
    name: 'MasterCard',
    icon: <MasterIcon />,
  },
  AMEX: {
    name: 'American Express',
    icon: <AmericanExpressIcon />,
  },
  DINERS: {
    name: 'Diners Club',
    icon: <DinersIcon />,
  },
  JCB: {
    name: 'JCB',
    icon: <JcbIcon />,
  },
}

export const getCardTypeName = (cardNumbers: string): string => {
  const firstDigit = cardNumbers.charAt(0)
  if (cardNumbers.length == 14 && firstDigit == '3') return 'DINERS'
  else if (cardNumbers.length == 15 && firstDigit == '3') return 'AMEX'
  else if (cardNumbers.length == 16) {
    switch (firstDigit) {
      case '4':
      case '2':
        return 'VISA'
      case '5':
        return 'MASTER'
      case '3':
        return 'JCB'
    }
  }
  return ''
}
