import React from 'react'
import Router from 'next/router'
import { event as gevent } from '@/pages/api/gtag'

import PageEdit from '@/assets/img/icons/PageEdit.svg'
import { StyledMyMenuItem, StyledMyMenuItemName } from '../index.style'

const goCreatorContentsPage = () => {
  gevent({
    action: 'menu_edit',
    category: 'other',
  })
  Router.push('/creator/contents')
}

const CreatorPage = () => {
  return (
    <StyledMyMenuItem onClick={goCreatorContentsPage} width="100%" flexDirectionRow>
      <PageEdit width={20} height={20} />
      <StyledMyMenuItemName>自分のページ</StyledMyMenuItemName>
    </StyledMyMenuItem>
  )
}

export default CreatorPage
