import styled from '@emotion/styled'
import { Colors } from '@/constants/styles/color'

const StyledBlogCardContainer = styled.div`
  width: calc(100% - 32px);
  height: 88px;
  padding: 8px;
  margin: 0 16px 16px;
  background: ${Colors.WHITE};
  display: grid;
  grid-template-columns: 108px auto;
  align-items: center;
`

const StyledBlogCardImg = styled.img`
  width: 108px;
  height: 72px;
  object-fit: cover;
`

const StyledBlogCardDetail = styled.div`
  width: calc(100% - 12px);
  height: 100%;
  margin-left: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const StyledBlogCardTitle = styled.div`
  color: ${Colors.PRIMARY_GRAY};
  font-size: 13px;
  font-weight: 500;
  line-height: 160%;
  overflow-wrap: break-word;
  word-break: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-align: left;
  height: 40px;
  overflow: hidden;
  margin-bottom: 8px;
`

const StyledBlogCardDateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const StyledBlogCardDate = styled.div`
  color: ${Colors.GRAY};
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
`

const StyledBlogCardLabel = styled.div<{ color?: string }>`
  color: ${props => props.color || Colors.PRIMARY_GRAY};
  border: ${props =>
    props.color ? `0.5px solid ${props.color}` : `0.5px solid ${Colors.PRIMARY_GRAY}`};
  font-size: 10px;
  font-weight: 500;
  line-height: 10px;
  border-radius: 8px;
  width: 56px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export {
  StyledBlogCardContainer,
  StyledBlogCardImg,
  StyledBlogCardDetail,
  StyledBlogCardTitle,
  StyledBlogCardDateContainer,
  StyledBlogCardDate,
  StyledBlogCardLabel,
}
