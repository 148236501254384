import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import copy from 'copy-to-clipboard'
import { MiniApp } from '@torihada-inc/miniapps'
import { SellerAccountGmoTransferResponse } from '@torihada-inc/miniapps/dist/repositories/user/SellerAccountTransferRepository'
import getConfig from 'next/config'
import { Colors } from '@/constants/styles/color'
import MuiButton from '@/components/atoms/MuiButton'
import Button from '@/components/atoms/Button'
import { useAuth } from '@/contexts/AuthContext'
import { getMiniAppInfo } from '@/pages/api/miniapp'

const Cover = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: -1;
  background: ${Colors.PURE_BLACK};
  opacity: 0.3;
`

const Popover = styled.div`
  position: absolute;
  z-index: 10001;
`

const ItemData = styled.div`
  max-width: 328px;
  background: ${Colors.WHITE};
  border-radius: 16px;
`

const PopTitle = styled.div`
  font-size: 15px;
  font-weight: 700;
  line-height: 15px;
  padding: 16px 0;
`
const AuthCodeContainer = styled.div`
  background: ${Colors.ULTRA_LIGHT_GRAY};
  border-radius: 4px;
  height: 56px;
  font-size: 30px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0.1em;
  color: ${Colors.PRIMARY_GRAY};
  margin: 0 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
`

const AuthCodeNotice = styled.div`
  margin-top: 40px;
  text-align: left;
  font-size: 11px;
  font-weight: 500;
  line-height: 160%;
  padding: 0 16px 16px;
  color: ${Colors.PRIMARY_GRAY};
  span {
    color: ${Colors.ORANGE};
  }
`

const AuthCodeButton = styled.div`
  margin: 16px auto 40px auto;
`

const BtnContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 24px;
`

const CancelButtonTop = styled.div`
  padding-top: 24px;
`

interface Props {
  accountId: string
  visible: boolean
  onClose: () => void
}

const { publicRuntimeConfig } = getConfig()

const MoneyManagementAuthentication = ({ accountId, visible, onClose }: Props) => {
  const { token } = useAuth()
  const [miniApp, setMiniApp] = useState<MiniApp | undefined>()
  const [GmoButton, setGmoButton] = useState(false)
  const [gmoTransferData, setGmoTransferData] = useState<SellerAccountGmoTransferResponse>()

  const initMiniApp = async () => {
    const res: any = await getMiniAppInfo(accountId)
    const miniAppData = new MiniApp(publicRuntimeConfig.PAYMENT_URL, res.user_uid, token as string)
    setMiniApp(miniAppData)
  }

  useEffect(() => {
    miniApp?.user.createSellerGmoTransfer().then((res: any) => {
      setGmoTransferData(res.data)
    })
  }, [miniApp])

  useEffect(() => {
    if (visible && accountId && token) {
      initMiniApp()
    }
  }, [accountId, token, visible])

  const openGmoURL = () => {
    window.open(gmoTransferData?.gmo_url)
  }

  const copyToClipboard = () => {
    setGmoButton(true)
    if (gmoTransferData?.auth_code) {
      copy(gmoTransferData?.auth_code)
    } else {
      copy('')
    }
  }

  if (!visible) return <></>

  return (
    <>
      <Popover>
        <Cover />
        <ItemData>
          <PopTitle>認証コードを発行</PopTitle>
          <AuthCodeContainer>{gmoTransferData?.auth_code}</AuthCodeContainer>
          <AuthCodeButton>
            <MuiButton
              colored
              text="認証コードをコピー"
              size="lg"
              width="160px"
              style={{
                height: '32px',
                fontWeight: 700,
                fontSize: '12px',
                lineHeight: '12px',
                margin: 'auto',
              }}
              onClick={() => copyToClipboard()}
            />
          </AuthCodeButton>
          <BtnContainer>
            <Button
              text={'GMOペイメントへ'}
              width={'100%'}
              height={'48px'}
              fontFamily={'Noto Sans JP'}
              fontWeight={'700'}
              fontSize={'12px'}
              lineHeight={'12px'}
              borderRadius={'24px'}
              bgColor={GmoButton ? Colors.PRIMARY_GRAY : Colors.PALE_GRAY}
              textColor={Colors.WHITE}
              onClick={() => GmoButton && openGmoURL()}
              disable={!GmoButton}
            />
          </BtnContainer>

          <AuthCodeNotice>
            *<span>認証コードをコピー</span>するとGMOペイメント(外部リンク)
            へのボタンがアクティブとなり遷移可能となります。
            <br />
            *本認証コードを口座情報の入力画面で入力してください。
            <br />
            *口座情報の入力画面の有効時間は10分です。 10分を過ぎた場合は
            しばらくたってから再度お試しください。
          </AuthCodeNotice>
        </ItemData>

        <CancelButtonTop />

        <BtnContainer>
          <Button
            text={'閉じる'}
            width={'248px'}
            height={'48px'}
            fontFamily={'Noto Sans JP'}
            fontWeight={'700'}
            fontSize={'12px'}
            lineHeight={'12px'}
            borderRadius={'24px'}
            bgColor={Colors.PRIMARY_GRAY}
            textColor={Colors.WHITE}
            onClick={() => onClose()}
          />
        </BtnContainer>
      </Popover>
    </>
  )
}

export default MoneyManagementAuthentication
