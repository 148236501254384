import styled from '@emotion/styled'
import { Colors } from '@/constants/styles/color'

const StyledCaption = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 13px;
  color: ${Colors.GRAY};
  text-align: left;
  margin-bottom: 16px;
`
const NoItemContainer = styled.div`
  display: flex;
  width: 100%;
  height: calc(100vh - 56px);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  color: ${Colors.LIGHT_GRAY};
`

export { StyledCaption, NoItemContainer }
