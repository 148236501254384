import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { MiniApp } from '@torihada-inc/miniapps'
import getConfig from 'next/config'
import { Colors } from '@/constants/styles/color'
import Button from '@/components/atoms/Button'
import { useAuth } from '@/contexts/AuthContext'
import { getMiniAppInfo } from '@/pages/api/miniapp'
import { getCreditAccountData, getCreditAccountRegister } from '@/pages/api/payment'

const Cover = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: -1;
  background: ${Colors.PURE_BLACK};
  opacity: 0.3;
`

const Popover = styled.div`
  position: absolute;
  z-index: 10001;
`

const ItemData = styled.div`
  max-width: 328px;
  background: ${Colors.WHITE};
  border-radius: 16px;
`

const PopTitle = styled.div`
  font-size: 15px;
  font-weight: 700;
  line-height: 15px;
  padding: 16px 0;
`
const BankContainer = styled.div`
  background: ${Colors.ULTRA_LIGHT_GRAY};
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0.1em;
  color: ${Colors.PRIMARY_GRAY};
  margin: 0 24px;
  display: flex;
  align-items: flex-start;
  text-align: left;
  padding: 10px 16px;
`
const BtnContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px 24px;
`

const CancelButtonTop = styled.div`
  padding-top: 24px;
`

export type SellerGmoAccountTransferResponse = {
  delete_flag?: string
  bank_name?: string
  bank_code?: string
  branch_name?: string
  branch_code?: string
  account_number?: string
  account_name?: string
  branch_code_jpbank?: string
  account_number_jpbank?: string
}

interface Props {
  accountId: string
  visible: boolean
  onClose: () => void
  authenticate: () => void
  backManagement: () => void
  errorState: () => void
  infoState: () => void
  updateState: () => void
}

const { publicRuntimeConfig } = getConfig()

const MoneyManagementApplication = ({
  accountId,
  visible,
  onClose,
  authenticate,
  backManagement,
  errorState,
  infoState,
  updateState,
}: Props) => {
  const { token } = useAuth()
  const [miniApp, setMiniApp] = useState<MiniApp | undefined>()
  const [gmoTransferAccountData, setGmoTransferAccountData] =
    useState<SellerGmoAccountTransferResponse>()
  const [isButtonAccessDisabled, setIsButtonAccessDisabled] = useState<boolean>(false)
  const [isButtonRegisterDisabled, setIsButtonRegisterDisabled] = useState<boolean>(false)
  const initMiniApp = async () => {
    const res: any = await getMiniAppInfo(accountId)
    const miniAppData = new MiniApp(publicRuntimeConfig.PAYMENT_URL, res.user_uid, token as string)
    setMiniApp(miniAppData)
  }

  //口座情報取得
  //取得中はボタンを押せないようにする
  //取得した場合は申請、口座登録/変更ボタンを押せるようにする
  const gmoTransfer = async () => {
    try {
      setIsButtonAccessDisabled(true)
      const res = await getCreditAccountData() //口座情報取得
      if (res) {
        setGmoTransferAccountData(res)
        setIsButtonAccessDisabled(false)
        setIsButtonRegisterDisabled(false)
      } else {
        gmoAccountRegister() //GMO口座登録に移動
      }
    } catch (e) {
      gmoAccountRegister() //GMO口座登録に移動
    }
  }

  //申請ボタンを押した場合
  //GMO画面に遷移するためのワンタイムパス/GMO URLを取得
  //取得できなかった場合は売上管理画面に戻る
  const gmoRegister = async () => {
    try {
      const creditAccount = await getCreditAccountRegister()

      if (creditAccount === true) {
        onClose()
        infoState()
        updateState()
      } else {
        onClose()
        errorState()
        updateState()
      }
    } catch (error) {
      onClose()
      errorState()
      updateState()
    }
  }

  //振込申請を行う
  const gmoAccount = () => {
    setIsButtonAccessDisabled(true)
    setIsButtonRegisterDisabled(true)
    backManagement() //売上管理画面に移動
    gmoRegister()
  }

  //GMO口座登録画面に移動
  const gmoAccountRegister = () => {
    setIsButtonAccessDisabled(true)
    setIsButtonRegisterDisabled(true)
    authenticate()
    onClose()
  }

  useEffect(() => {
    if (visible) {
      gmoTransfer()
    }
  }, [miniApp, accountId])

  useEffect(() => {
    if (visible && accountId && token) {
      initMiniApp()
    }
  }, [accountId, token, visible])

  if (!visible) return <></>

  return (
    <>
      <Popover>
        <Cover />
        <ItemData>
          <PopTitle>
            下記の口座宛に振込申請を行います。
            <br />
            よろしいですか？
          </PopTitle>
          <BankContainer>
            {isButtonAccessDisabled ? (
              <>口座情報取得中...</>
            ) : (
              <>
                {gmoTransferAccountData?.bank_name}({gmoTransferAccountData?.bank_code})
                <br />
                {gmoTransferAccountData?.branch_name}({gmoTransferAccountData?.branch_code})
                <br />
                口座番号：{gmoTransferAccountData?.account_number}
                <br />
                口座名義人：{gmoTransferAccountData?.account_name}
              </>
            )}
          </BankContainer>

          <BtnContainer>
            <Button
              text={'申請する'}
              width={'100%'}
              height={'48px'}
              fontFamily={'Noto Sans JP'}
              fontWeight={'700'}
              fontSize={'12px'}
              lineHeight={'12px'}
              borderRadius={'24px'}
              bgColor={isButtonAccessDisabled ? Colors.PALE_GRAY : Colors.FANME_YELLOW}
              textColor={isButtonAccessDisabled ? Colors.WHITE : Colors.BLACK}
              onClick={gmoAccount}
              disable={isButtonAccessDisabled}
            />
          </BtnContainer>
          <BtnContainer>
            <Button
              text={'口座情報を変更して申請する'}
              width={'100%'}
              height={'48px'}
              fontFamily={'Noto Sans JP'}
              fontWeight={'700'}
              fontSize={'12px'}
              lineHeight={'12px'}
              borderRadius={'24px'}
              bgColor={isButtonRegisterDisabled ? Colors.PALE_GRAY : Colors.WHITE}
              textColor={isButtonRegisterDisabled ? Colors.WHITE : Colors.BLACK}
              onClick={gmoAccountRegister}
              border={
                isButtonRegisterDisabled
                  ? `1px solid ${Colors.PALE_GRAY}`
                  : `1px solid ${Colors.BLACK}`
              }
              disable={isButtonRegisterDisabled}
            />
          </BtnContainer>
        </ItemData>

        <CancelButtonTop />

        <BtnContainer>
          <Button
            text={'閉じる'}
            width={'248px'}
            height={'48px'}
            fontFamily={'Noto Sans JP'}
            fontWeight={'700'}
            fontSize={'12px'}
            lineHeight={'12px'}
            borderRadius={'24px'}
            bgColor={Colors.PRIMARY_GRAY}
            textColor={Colors.WHITE}
            onClick={onClose}
          />
        </BtnContainer>
      </Popover>
    </>
  )
}

export default MoneyManagementApplication
