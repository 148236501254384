import React, { useState } from 'react'
import { Colors } from '@/constants/styles/color'
import BlogArticleBadge from '@/components/molecules/BlogArticleBadge'
import {
  StyledBadgeInnerContainer,
  StyledBadgeOuterContainer,
} from '@/components/molecules/BlogArticleBadge/index.style'
import BlogArticleDetail from '../BlogArticalDetail'
import {
  StyledBlogCardContainer,
  StyledBlogCardDate,
  StyledBlogCardDateContainer,
  StyledBlogCardDetail,
  StyledBlogCardImg,
  StyledBlogCardLabel,
  StyledBlogCardTitle,
} from './index.style'

interface Props {
  id: number
  visible: boolean
  title: string
  description: string
  content: string
  image_url: string
  label: number
  priority: number
  published_at: string
  published_at_hh_mm: string
  readed: boolean
  onClose?: () => void
}

const BlogCard = ({
  id,
  image_url,
  title,
  description,
  label,
  published_at,
  published_at_hh_mm,
  content,
  readed,
  onClose,
}: Props) => {
  const [visibleBlogDetail, setVisibleBlogDetail] = useState(false)
  const getLabelInfo = (label: number) => {
    if (label === 0) return ' お知らせ '
    else if (label === 1) return ' 重要 '
    else if (label === 2) return ' イベント '
    else if (label === 3) return ' その他 '
  }
  const getLabelColor = (label: number) => {
    if (label == 0) return Colors.ORANGE
    else if (label === 1) return Colors.RED
    else if (label === 2) return Colors.LIGHT_BLUE
    else if (label === 3) return Colors.DARK_GRAY
  }
  return (
    <>
      <StyledBadgeOuterContainer>
        <StyledBlogCardContainer onClick={() => setVisibleBlogDetail(true)}>
          <StyledBadgeInnerContainer right={22} top={7}>
            {!readed && <BlogArticleBadge />}
          </StyledBadgeInnerContainer>
          <StyledBlogCardImg src={image_url} alt="" />
          <StyledBlogCardDetail>
            <StyledBlogCardTitle>{title}</StyledBlogCardTitle>
            <StyledBlogCardDateContainer>
              <StyledBlogCardDate>{published_at}</StyledBlogCardDate>
              <StyledBlogCardLabel color={getLabelColor(label)}>
                {getLabelInfo(label)}
              </StyledBlogCardLabel>
            </StyledBlogCardDateContainer>
          </StyledBlogCardDetail>
        </StyledBlogCardContainer>
      </StyledBadgeOuterContainer>

      {visibleBlogDetail && (
        <BlogArticleDetail
          id={id}
          visible={visibleBlogDetail}
          onClose={() => {
            setVisibleBlogDetail(false)
            onClose && onClose()
          }}
          title={title}
          description={description}
          content={content}
          image_url={image_url}
          label={label}
          published_at={published_at}
          published_at_hh_mm={published_at_hh_mm}
          priority={0}
        />
      )}
    </>
  )
}
export default BlogCard
