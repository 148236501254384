import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { MiniApp } from '@torihada-inc/miniapps'
import getConfig from 'next/config'
import { snakeToCamelObjectInArray } from '@/utils/base'
import { useFanmeBackendGet } from '@/libs/fanme_backend'
import { PurchaseHistoryWithTip } from '@/types/Purchase'
import Arrow_Right from '@/assets/img/icons/Arrow_Right.svg'
import { getMiniAppInfo } from '@/pages/api/miniapp'
import { useAuth } from '@/contexts/AuthContext'
import FullModal from '@/components/atoms/Dialog/FullModal'
import FullModalHeader from '@/components/molecules/Header/FullModalHeader'
import { InfiniteScrollContainer } from '@/components/templates/InfiniteScroll'
import FanmeLogoGray from '@/assets/img/logo/LOGO_Gray.svg'
import PaymentContent from '../PaymentContent'
import {
  StyledHistoryItem,
  StyledHistoryItemGrid,
  StyledItemImage,
  StyledItemName,
  StyledItemNonImage,
  StyledItemPrice,
  StyledItemPurchasedDate,
} from './index.style'

interface Props {
  accountId: string
  visible: boolean
  onClose: () => void
}

const limit = 10

const BrowsePaymentHistory = ({ accountId, visible, onClose }: Props) => {
  const { token } = useAuth()
  const { publicRuntimeConfig } = getConfig()
  const [visiblePopup, setVisiblePopup] = useState(false)
  const [miniApp, setMiniApp] = useState<MiniApp | undefined>()

  const [purchaseList, setPurchaseList] = useState<PurchaseHistoryWithTip[]>([])
  const [purchaseItem, setPurchaseItem] = useState<PurchaseHistoryWithTip>()
  const [currentList, setCurrentList] = useState<PurchaseHistoryWithTip[]>([])

  const { data: historyData } = useFanmeBackendGet<PurchaseHistoryWithTip[]>(
    `${publicRuntimeConfig.PAYMENT_URL}/user/purchase_histories`,
  )

  const fetchMore = () => {
    const currentLength = currentList.length
    const newList = purchaseList.slice(0, currentLength + limit)
    setCurrentList(newList)
  }

  useEffect(() => {
    if (visible === false) setVisiblePopup(false)
  }, [visible])

  const initMiniApp = async () => {
    const res: any = await getMiniAppInfo(accountId)

    const miniAppData = new MiniApp(publicRuntimeConfig.PAYMENT_URL, res.user_uid, token as string)
    setMiniApp(miniAppData)
  }

  const toNext = (purchase: PurchaseHistoryWithTip) => {
    setPurchaseItem(purchase)
    setVisiblePopup(true)
  }

  useEffect(() => {
    if (!historyData) return
    const sortedHistory = snakeToCamelObjectInArray(historyData)
      .map(item => {
        if (item.purchasedAt) {
          item.purchasedAt = dayjs(item.purchasedAt).set('hour', dayjs(item.purchasedAt).hour() + 9)
        }
        return item
      })
      .sort((a: any, b: any) => (a.purchasedAt > b.purchasedAt ? -1 : 1))
    setPurchaseList(sortedHistory)
    setCurrentList(sortedHistory.slice(0, limit))
  }, [historyData])

  useEffect(() => {
    if (token) {
      initMiniApp()
    }
  }, [accountId, token])

  const renderHeader = () => <FullModalHeader title="購入履歴" onBack={onClose} />

  const renderContent = () => (
    <>
      <InfiniteScrollContainer fetchMore={fetchMore}>
        {currentList.map((purchase, index) => {
          return (
            <StyledHistoryItem key={index} onClick={() => toNext(purchase)}>
              {purchase.tipId ? (
                <div className="w-[72px] h-[72px]" />
              ) : purchase.sellerAppItemIconUrl ? (
                <StyledItemImage
                  src={purchase.sellerAppItemIconUrl}
                  alt={purchase.sellerAppItemName}
                />
              ) : (
                <StyledItemNonImage>
                  <FanmeLogoGray
                    viewBox="-40 -20 300 300"
                    className="block mt-[10px] mx-0 mb-auto"
                  />
                </StyledItemNonImage>
              )}

              <StyledHistoryItemGrid>
                <StyledItemName>
                  {purchase.tipId ? 'チップ' : purchase.sellerAppItemName}
                </StyledItemName>
                <StyledItemPrice>¥{purchase.price?.toLocaleString()}</StyledItemPrice>

                <StyledItemPurchasedDate>
                  {purchase.purchasedAt?.format('YYYY.MM.DD HH:mm')}
                </StyledItemPurchasedDate>
              </StyledHistoryItemGrid>
              <Arrow_Right className="w-4 h-4 my-auto mr-0 ml-auto" />
            </StyledHistoryItem>
          )
        })}
      </InfiniteScrollContainer>
    </>
  )

  return (
    <>
      <FullModal
        header={renderHeader()}
        content={renderContent()}
        visible={visible}
        zIndex={10001}
        bodyPadding="0"
      />

      <PaymentContent
        visible={visiblePopup}
        onClose={() => setVisiblePopup(false)}
        item={purchaseItem}
      />
    </>
  )
}

export default BrowsePaymentHistory
