import styled from '@emotion/styled'
import { Colors } from '@/constants/styles/color'

const StyledMenuItemContainer = styled.div`
  max-width: 100%;
  width: calc(100% - 32px);
  height: 72px;
  padding-left: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${Colors.VERY_LIGHT_GRAY};
  margin: auto;
`

const StyledMenuItemTitle = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 16px 1fr;
  justify-items: center;
  column-gap: 12px;
`

const StyledMenuItemText = styled.div`
  font-size: 13px;
  font-weight: 500;
  line-height: 13px;
  color: ${Colors.PRIMARY_GRAY};
`

export { StyledMenuItemContainer, StyledMenuItemTitle, StyledMenuItemText }
