import React from 'react'
import Router from 'next/router'
import MylibraryIcon from '@/assets/img/icons/Library.svg'
import { StyledMyMenuItem, StyledMyMenuItemName } from '../index.style'

const MylibraryMenu = () => {
  const handleClick = () => {
    Router.push('/mylibrary')
  }
  return (
    <>
      <StyledMyMenuItem onClick={handleClick} className="cursor-pointer">
        <MylibraryIcon width={20} height={20} />
        <StyledMyMenuItemName>購入済みコンテンツ</StyledMyMenuItemName>
      </StyledMyMenuItem>
    </>
  )
}

export default MylibraryMenu
