import React, { FC, useEffect, useState } from 'react'
import { Container } from '@nextui-org/react'
import getConfig from 'next/config'
import Router from 'next/router'
import { event as gevent } from '@/pages/api/gtag'

import { Colors } from '@/constants/styles/color'

import Button from '@/components/atoms/Button'
import Close from '@/assets/img/icons/Close_BK_W_Circle.svg'
import Logo_Rise_BK from '@/assets/img/logo/Logo_Rise_BK.svg'

const { publicRuntimeConfig } = getConfig()

interface Props {
  windowWidth: number
  visible: boolean
  setVisible: (visible: boolean) => void
  currentPath?: string
}

const SideBar: FC<Props> = ({ windowWidth, visible, setVisible, currentPath }) => {
  const [bottom, setBottom] = useState(0)
  useEffect(() => {
    const elemLoginMenu = document.getElementById('loginMenu')
    if (elemLoginMenu) {
      setBottom(elemLoginMenu.clientHeight - window.innerHeight)
    }
  }, [])

  const toSignIn = () => {
    gevent({
      action: 'page_login',
      category: 'login',
      transport: 'beacon',
      cb: () => {
        Router.push(
          `${publicRuntimeConfig.AUTH_URL}?return_url=${publicRuntimeConfig.FRONT_URL}${currentPath}`,
        )
      },
    })
  }

  const toSignUp = () => {
    gevent({
      action: 'page_newcreate',
      category: 'register',
      transport: 'beacon',
      cb: () => {
        Router.push(`${publicRuntimeConfig.AUTH_URL}?proc=signup`)
      },
    })
  }
  return (
    <nav
      id="loginMenu"
      style={{
        background: Colors.WHITE,
        position: 'fixed',
        zIndex: 2,
        top: 0,
        right: windowWidth > 480 ? 'calc((100vw - 480px) / 2)' : 0,
        transform: visible ? 'translateX(0)' : 'translateX(100%)',
        opacity: visible ? 1 : 0,
        visibility: visible ? 'visible' : 'hidden',
        overflowX: 'hidden',
        textAlign: 'center',
        maxWidth: '263px',
        width: '90%',
        height: '100vh',
        transition: 'all 0.3s',
        paddingBottom: bottom > 0 ? `${bottom}px` : 0,
      }}
    >
      <div
        style={{
          width: '32px',
          height: '32px',
          borderRadius: '50%',
          display: 'flex',
          margin: '12px 12px 4px auto',
        }}
        onClick={() => setVisible(false)}
      >
        <Close
          style={{
            width: '100%',
            height: '100%',
          }}
        />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 8, paddingLeft: '24px' }}>
        <div
          style={{
            fontSize: '28px',
            fontWeight: 700,
            lineHeight: '150%',
            letterSpacing: '5.6px',
            textAlign: 'left',
          }}
        >
          あなたを
          <br />
          知りたい人が
          <br />
          もっと増える
        </div>
        <div
          style={{
            fontSize: '16px',
            fontWeight: 700,
            letterSpacing: '1.6px',
            textAlign: 'left',
          }}
        >
          次世代クリエイターSNS
        </div>
      </div>
      <Container
        css={{
          marginTop: '96px',
          marginBottom: '64px',
          p: 0,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Logo_Rise_BK width={88 * 2} height={56 * 2} />
      </Container>
      <Container id="loginMenuBottom" css={{ d: 'grid', gridRowGap: '24px', marginBottom: '24px' }}>
        <Button
          text="ログイン"
          width="100%"
          height="48px"
          fontFamily="Noto Sans JP"
          fontSize="14px"
          fontWeight="700"
          borderRadius="24px"
          bgColor={Colors.BLACK}
          textColor={Colors.WHITE}
          onClick={toSignIn}
        />
        <Button
          text="アカウント作成"
          width="100%"
          height="48px"
          fontFamily="Noto Sans JP"
          fontSize="14px"
          fontWeight="700"
          borderRadius="24px"
          bgColor={Colors.FANME_YELLOW}
          textColor={Colors.BLACK}
          onClick={toSignUp}
        />
      </Container>
    </nav>
  )
}

export default SideBar
