import getConfig from 'next/config'
import { get, post } from '@/pages/api/base'
import { useFanmeBackendGet } from '@/libs/fanme_backend'

const { publicRuntimeConfig } = getConfig()

export const userVerificationStatusNone = 0
export const userVerificationStatusDone = 1
export const userVerificationStatusPending = 2

export const userVerificationStatusSubmitted = 3
export const userVerificationStatusDenied = -1

export const getUserVerificationStat = async (token?: string) => {
  if (!token) {
    return null
  }
  return await get(`${publicRuntimeConfig.AUTH_DOMAIN}/api/user/verification/stat`, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

export const useUserVerificationStat = () =>
  useFanmeBackendGet<any>('/auth/api/user/verification/stat')

export const postApplyVerification = async (token: string, data: any) => {
  // FIXME: use BFF
  const res = await post(`${publicRuntimeConfig.AUTH_DOMAIN}/api/user/verification/apply`, data, {
    headers: { Authorization: `Bearer ${token}` },
  })
  if (res.data?.redirect_url) {
    window.location.href = res.data.redirect_url
  }

  return res.data
}

export const issueVerificationUrl = async (token: string, options: any = {}) => {
  const res = await post(
    `${publicRuntimeConfig.AUTH_DOMAIN}/api/user/verification/issue-url`,
    options,
    {
      headers: { Authorization: `Bearer ${token}` },
    },
  )
  if (res.data?.redirect_url) {
    window.location.href = res.data.redirect_url
  }

  return res.data
}
