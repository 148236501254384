import React, { PropsWithChildren } from 'react'
import styled from '@emotion/styled'
import numeral from 'numeral'
import { Colors } from '@/fanmeUi/constants/style'

const Box = styled.div<{ shadow?: string | boolean }>`
  background-color: ${Colors.WHITE};
  box-shadow: ${({ shadow }) =>
    shadow === false
      ? 'none'
      : shadow || `0 1px 1px rgba(0, 0, 0, 0.05), 0 0 15px rgba(0, 0, 0, 0.1)`};
  overflow: hidden;
  border-radius: 8px;
`

const LabeledBox = styled.div`
  border-radius: 12px;
  overflow: hidden;
  background-color: ${Colors.WHITE};
  text-align: center;
`
const LabeledBoxInner = styled.div`
  padding: 8px;
  > .number {
    font-weight: 700;
    font-size: 24px;
  }
  > .changes {
    font-weight: 700;
    font-size: 16px;
    &.is-positive {
      color: ${Colors.SUCCESS};
    }
    &.is-negative {
      color: ${Colors.RED};
    }
    &.is-no-change {
      color: ${Colors.GRAY};
    }
  }
`
const LabeledBoxTitle = styled.div`
  background-color: ${Colors.BLACK};
  font-size: 16px;
  padding: 8px 0;
  color: ${Colors.WHITE};
`

type FmeBoxProps = { shadow?: boolean | string } & WithChildren
const FmeBox = ({ shadow, children }: FmeBoxProps) => {
  return <Box shadow={shadow}>{children}</Box>
}
const FmeBoxInner = styled.div`
  padding: 16px;
`
type FmeBorderBoxProps = {
  width?: number
  height?: number
} & WithChildren
const BorderBox = styled.div<{ width?: number; height?: number }>`
  border: 1px solid ${Colors.BLACK};
  width: ${({ width }) => width || 168}px;
  margin: 0px auto;
  height: ${({ height }) => height || 32}px;
  align-items: center;
  display: flex;
  justify-content: center;
  letter-spacing: 0.1px;
  color: ${Colors.BLACK};
`
const FmeBorderBox = ({ children, width, height }: WithChildren & FmeBorderBoxProps) => {
  return (
    <BorderBox width={width} height={height}>
      {children}
    </BorderBox>
  )
}

type FmeLabeledBoxProps = {
  title: string
} & PropsWithChildren
const FmeLabeledBox = ({ title, children }: FmeLabeledBoxProps) => {
  return (
    <LabeledBox>
      <LabeledBoxTitle>{title}</LabeledBoxTitle>
      {children}
    </LabeledBox>
  )
}
type FmeLabeledBoxNumberProps = {
  num: number
  diff?: number
  loaded?: boolean
}
const FmeLabeledBoxNumber = ({ num, diff, loaded = true }: FmeLabeledBoxNumberProps) => {
  return (
    <LabeledBoxInner>
      {loaded ? (
        <>
          <strong className={`number`}>{numeral(num).format('0,0')}</strong>
          {diff === undefined || false ? null : (
            <p
              className={`changes${
                diff > 0 ? ' is-positive' : diff < 0 ? ' is-negative' : ' is-no-change'
              }`}
            >
              {diff === 0 ? (
                '-'
              ) : (
                <>
                  {diff > 0 ? '+' : ''}
                  {numeral(diff).format('0,0')}
                </>
              )}
            </p>
          )}
        </>
      ) : (
        <strong className={`number`}>-</strong>
      )}
    </LabeledBoxInner>
  )
}

export { FmeBox, FmeBoxInner, FmeBorderBox, FmeLabeledBox, FmeLabeledBoxNumber }
