import { Container, Link, Text } from '@nextui-org/react'
import React, { useEffect, useState } from 'react'
import getConfig from 'next/config'
import { Colors } from '@/constants/styles/color'
import PAYMENT_HISTORY_VIEW_ICON from '@/assets/img/logo/FanmeIcon.webp'
import { getAccountIdentity } from '@/pages/api/creator'
import FullModal from '@/components/atoms/Dialog/FullModal'
import FullModalHeader from '@/components/molecules/Header/FullModalHeader'
import FanmeLogoGray from '@/assets/img/logo/LOGO_Gray.svg'
import YellIcon from '@/assets/img/icons/Yell.svg'
import { getAppOldInfo } from '@/pages/api/miniapp'
import { PurchaseHistoryWithTip } from '@/types/Purchase'
import {
  StyledAppItemDescription,
  StyledAppItemImg,
  StyledAppItemNoneImg,
  StyledAppItemTitle,
  StyledPurchasedAtContainer,
  StyledPurchasedAtDetail,
  StyledPurchasedAtDetailDate,
  StyledPurchasedAtDetailName,
  StyledPurchasedAtDetailPrice,
} from './index.style'

const { publicRuntimeConfig } = getConfig()

interface Props {
  visible: boolean
  item?: PurchaseHistoryWithTip
  onClose?: () => void
  onPrev?: () => void
}

const BrowsePaymentContent = ({ visible, item, onClose, onPrev }: Props) => {
  const [seller, setSeller] = useState<any>({})
  const [sellerApp, setSellerApp] = useState<any>()

  const HeaderAvatarIcon = '/Avatar_Default_Big.webp'

  useEffect(() => {
    if (item && item.sellerUserId) {
      getAccountIdentity(item.sellerUserId).then(res => {
        setSeller(res)
      })
    }
    if (item?.sellerAppId) {
      getAppOldInfo(String(item.sellerAppId)).then(res => {
        setSellerApp(res.data)
      })
    }
  }, [item])

  const renderHeader = () => <FullModalHeader title="購入履歴" onBack={onClose} />

  const renderContent = () => {
    if (!item) return null
    return (
      <>
        {item.tipId ? (
          'チップ'
        ) : item.sellerAppItemIconUrl ? (
          <StyledAppItemImg src={item.sellerAppItemIconUrl} alt="" />
        ) : (
          <StyledAppItemNoneImg>
            <FanmeLogoGray viewBox="10 -80 200 200" />
          </StyledAppItemNoneImg>
        )}
        <StyledAppItemTitle>{item!.sellerAppItemName}</StyledAppItemTitle>

        <StyledAppItemDescription>{item!.sellerAppItemDescription}</StyledAppItemDescription>

        <StyledPurchasedAtContainer>
          <div className="flex justify-between items-center">
            <div className="text-[11px]">購入価格</div>
            <div className="w-[60%]">
              <StyledPurchasedAtDetail>
                <StyledPurchasedAtDetailName>
                  {item.tipId ? 'チップ料金' : '商品金額'}
                </StyledPurchasedAtDetailName>
                <StyledPurchasedAtDetailPrice>
                  <span className="span-price">¥{item!.price}</span>
                  <span className="text-[9px] font-normal ml-1">(税込)</span>
                </StyledPurchasedAtDetailPrice>
              </StyledPurchasedAtDetail>
              <StyledPurchasedAtDetail>
                <StyledPurchasedAtDetailName>合計</StyledPurchasedAtDetailName>
                <StyledPurchasedAtDetailPrice>
                  <span className="span-price">¥{item!.price}</span>
                  <span className="text-[9px] font-normal ml-1">(税込)</span>
                </StyledPurchasedAtDetailPrice>
              </StyledPurchasedAtDetail>
              {/* エールに関するコード一旦コメントアウト */}
              {/* <StyledPurchasedAtDetail>
                <StyledPurchasedAtDetailName>
                  <YellIcon className="inline-block mr-1 align-middle" />
                  <span className="inline-block align-middle">獲得エール数</span>
                </StyledPurchasedAtDetailName>
                <StyledPurchasedAtDetailPrice>
                  <span className="span-price">{item!.price}</span>
                </StyledPurchasedAtDetailPrice>
              </StyledPurchasedAtDetail> */}
            </div>
          </div>
          <div className="text-left text-GRAY font-normal text-[11px] py-2 border-PALE_GRAY border-b-[1px] mb-[15px]">
            ※合計金額内サービス手数料も含まれます
          </div>
          <div className="flex justify-between">
            <div className="text-left font-medium text-[11px]">購入日</div>
            <StyledPurchasedAtDetailDate>
              {item!.purchasedAt?.year()}/{('00' + (item!.purchasedAt!.month() + 1)).slice(-2)}/
              {('00' + item!.purchasedAt?.date()).slice(-2)}{' '}
              {('00' + item!.purchasedAt?.add(9, 'hour').hour()).slice(-2)}:
              {('00' + item!.purchasedAt?.minute()).slice(-2)}
            </StyledPurchasedAtDetailDate>
          </div>
        </StyledPurchasedAtContainer>

        {seller && seller.name ? (
          <Container
            css={{
              d: 'flex',
              alignItems: 'center',
              w: '100%',
              m: '24px 0 0 0',
              p: 0,
            }}
          >
            <div
              style={{
                width: '100%',
                display: 'grid',
                gridTemplateColumns: 'max-content auto',
                alignItems: 'center',
                overflow: 'hidden',
              }}
            >
              <Link
                href={`${publicRuntimeConfig.FRONT_URL}/@${seller!.account_identity}/apps/${
                  sellerApp?.app_url_key
                }${sellerApp?.seller_url_key}`}
                style={{
                  marginTop: 'auto',
                  marginBottom: 'auto',
                }}
              >
                <img
                  src={
                    item!.sellerAppImageUrl1
                      ? item!.sellerAppImageUrl1
                      : PAYMENT_HISTORY_VIEW_ICON.src
                  }
                  height="72"
                  alt=""
                  style={{
                    margin: 'auto',
                    aspectRatio: '12/7',
                    height: '72px',
                  }}
                />
              </Link>
              <Container
                css={{
                  w: '100%',
                  h: '100%',
                  p: '0 0 0 12px',
                  d: 'grid',
                  alignItems: 'center',
                  gridTemplateRows: '40px 32px',
                }}
              >
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    overflow: 'hidden',
                    display: 'flex',
                  }}
                >
                  <Link
                    href={`${publicRuntimeConfig.FRONT_URL}/@${seller!.account_identity}/apps/${
                      sellerApp?.app_url_key
                    }${sellerApp?.seller_url_key}`}
                    style={{
                      width: '100%',
                      height: '100%',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                    }}
                  >
                    <Text
                      className="jp-title"
                      css={{
                        fontSize: '12px',
                        fontWeight: '500',
                        lineHeight: '19px',
                        letterSpacing: '0em',
                        color: Colors.ORANGE,
                        textAlign: 'left',
                        overflow: 'hidden',
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: '2',
                        overflowWrap: 'break-word',
                        whiteSpace: 'pre-line',
                      }}
                    >
                      {item!.sellerAppItemName}
                    </Text>
                  </Link>
                </div>
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    overflow: 'hidden',
                    display: 'flex',
                  }}
                >
                  <Link
                    href={`${process.env.CREATOR_URL}/@${seller!.account_identity}`}
                    style={{
                      width: '100%',
                      height: '100%',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      src={seller!.icon ? seller!.icon : HeaderAvatarIcon}
                      width={24}
                      height={24}
                      alt=""
                      style={{
                        borderRadius: '50%',
                      }}
                    />
                    <Text
                      className="jp-text"
                      css={{
                        width: 'calc(100% - 24px)',
                        fontSize: '12px',
                        fontWeight: '500',
                        lineHeight: '12px',
                        letterSpacing: '0em',
                        color: Colors.BLACK,
                        textAlign: 'left',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        paddingLeft: '6px',
                      }}
                    >
                      {seller!.name}
                    </Text>
                  </Link>
                </div>
              </Container>
            </div>
          </Container>
        ) : (
          ''
        )}
      </>
    )
  }

  return (
    <FullModal
      header={renderHeader()}
      content={renderContent()}
      visible={visible}
      zIndex={10002}
      bodyPadding="24px"
    />
  )
}

export default BrowsePaymentContent
