import React, { useState } from 'react'
import MoneyManagementIcon from '@/assets/img/icons/MoneyManagement.svg'
import MoneyManagementList from '../MoneyManagementList'
import { StyledMyMenuItem, StyledMyMenuItemName } from '../index.style'

interface Props {
  accountIdentity: string
}

const MoneyManagement = ({ accountIdentity }: Props) => {
  const [visible, setVisible] = useState(false)
  return (
    <>
      <StyledMyMenuItem onClick={() => setVisible(true)} width="144px">
        <MoneyManagementIcon width={24} height={24} />
        <StyledMyMenuItemName>売上金管理</StyledMyMenuItemName>
      </StyledMyMenuItem>

      <MoneyManagementList
        accountId={accountIdentity}
        visible={visible}
        onClose={() => setVisible(false)}
      />
    </>
  )
}

export default MoneyManagement
