import React from 'react'
import News from '@/assets/img/icons/News.svg'
import { StyledMyMenuItem, StyledMyMenuItemName } from '../index.style'

const goTwitterAccountPage = () => {
  location.href = 'https://twitter.com/Fanme__official'
}

const TwitterPage = () => {
  return (
    <StyledMyMenuItem onClick={goTwitterAccountPage}>
      <News width={20} height={20} />
      <StyledMyMenuItemName>お知らせ</StyledMyMenuItemName>
    </StyledMyMenuItem>
  )
}

export default TwitterPage
