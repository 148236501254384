import React, { useState } from 'react'
import getConfig from 'next/config'
import Router from 'next/router'
import { useModal } from '@nextui-org/react'
import { destroyCookieFunc } from '@/contexts/AuthContext'
import Email from '@/assets/img/icons/Email.svg'
import Place from '@/assets/img/icons/Place.svg'
import Settings from '@/assets/img/icons/Settings.svg'
import ArrowRight from '@/assets/img/icons/Arrow_Right.svg'
import PaymentHistoryIcon from '@/assets/img/icons/PaymentHistory.svg'
import CreditCard from '@/assets/img/icons/CreditCard.svg'
import Logout from '@/assets/img/icons/Logout.svg'
import AuthMenuPopup from '@/components/molecules/AuthMenuPopup'
import FullModal from '@/components/atoms/Dialog/FullModal'
import FullModalHeader from '@/components/molecules/Header/FullModalHeader'
import AddAddress, { AddressInfo } from '@/fanmeSdk/Payment/AddAddress'
import { getAddressInfo } from '@/pages/api/payment'
import AlertDialog, { AlertState } from '../../Alert/alert'
import BrowsePaymentHistory from '../BrowsePaymentHistory'
import PaymentMethod from '../PaymentMethod'
import { StyledMyMenuItem, StyledMyMenuItemName } from '../index.style'
import Profile from '../Profile'
import { StyledMenuItemContainer, StyledMenuItemText, StyledMenuItemTitle } from './index.style'

const { publicRuntimeConfig } = getConfig()

interface Props {
  accountIdentity: string
}

const AccountSettings = ({ accountIdentity }: Props) => {
  const { setVisible, visible } = useModal()
  const [alertState, setAlertState] = useState({} as AlertState)
  const [visibleAuthMenu, setVisibleAuthMenu] = useState(false)
  const [visiblePaymentHistory, setVisiblePaymentHistory] = useState(false)
  const [visiblePaymentMethod, setVisiblePaymentMethod] = useState(false)
  const [visibleAddAddress, setVisibleAddAddress] = useState(false)
  const [addressInfo, setAddressInfo] = useState<AddressInfo | null>(null)

  const handleAddAddress = async () => {
    setVisibleAddAddress(true)
    await getAddress()
  }

  const getAddress = async () => {
    try {
      const addressInfo = await getAddressInfo()
      if (addressInfo?.every((v: any) => v === null)) {
        setAddressInfo(null)
      } else {
        setAddressInfo(addressInfo)
      }
    } catch (error) {
      console.error('Error fetching address info:', error)
      setAddressInfo(null)
    }
  }

  const logout = () => {
    const params = {
      redirect_url: location.href,
    }
    const query_params = new URLSearchParams(params)
    query_params.delete('menu')
    Router.push(`${publicRuntimeConfig.AUTH_DOMAIN}/signout?${query_params}`)
    destroyCookieFunc()
  }

  const logoutAlert = {
    message: 'ログアウトしますか？',
    actionMessage: 'ログアウト',
    action: () => {
      setAlertState({} as AlertState)
      logout()
    },
    close: setAlertState,
    visible: true,
  }

  const renderHeader = () => (
    <FullModalHeader
      title="アカウント管理"
      onBack={() => {
        setVisible(false)
      }}
    />
  )

  const renderContent = () => (
    <>
      <Profile />
      <StyledMenuItemContainer onClick={() => setVisibleAuthMenu(true)}>
        <StyledMenuItemTitle>
          <Email width={16} height={12} />
          <StyledMenuItemText>メールアドレス</StyledMenuItemText>
        </StyledMenuItemTitle>
        <ArrowRight width={16} height={16} />
      </StyledMenuItemContainer>

      <StyledMenuItemContainer onClick={() => setVisiblePaymentHistory(true)}>
        <StyledMenuItemTitle>
          <PaymentHistoryIcon width={20} height={20} />
          <StyledMenuItemText>購入履歴</StyledMenuItemText>
        </StyledMenuItemTitle>
        <ArrowRight width={16} height={16} />
      </StyledMenuItemContainer>

      <StyledMenuItemContainer onClick={() => setVisiblePaymentMethod(true)}>
        <StyledMenuItemTitle>
          <CreditCard width={16} height={12} />
          <StyledMenuItemText>お支払い方法</StyledMenuItemText>
        </StyledMenuItemTitle>
        <ArrowRight width={16} height={16} />
      </StyledMenuItemContainer>

      <StyledMenuItemContainer onClick={handleAddAddress}>
        <StyledMenuItemTitle>
          <Place width={20} height={20} />
          <StyledMenuItemText>配送先登録</StyledMenuItemText>
        </StyledMenuItemTitle>
        <ArrowRight width={16} height={16} />
      </StyledMenuItemContainer>

      <StyledMenuItemContainer onClick={() => setAlertState(logoutAlert)}>
        <StyledMenuItemTitle>
          <Logout width={16} height={16} />
          <StyledMenuItemText>ログアウト</StyledMenuItemText>
        </StyledMenuItemTitle>
        <ArrowRight width={16} height={16} />
      </StyledMenuItemContainer>

      <AlertDialog
        message={alertState.message}
        actionMessage={alertState.actionMessage}
        action={alertState.action}
        close={alertState.close}
        visible={alertState.visible}
      />
      <BrowsePaymentHistory
        accountId={accountIdentity}
        visible={visiblePaymentHistory}
        onClose={() => setVisiblePaymentHistory(false)}
      />
      <PaymentMethod
        accountId={accountIdentity}
        visible={visiblePaymentMethod}
        onClose={() => setVisiblePaymentMethod(false)}
        chooseCard={true}
      />

      {visibleAddAddress && (
        <AddAddress backTo={() => setVisibleAddAddress(false)} addressInfo={addressInfo} />
      )}

      <AuthMenuPopup visible={visibleAuthMenu} onClose={() => setVisibleAuthMenu(false)} />
    </>
  )

  return (
    <>
      <StyledMyMenuItem onClick={() => setVisible(true)}>
        <Settings width={20} height={20} />
        <StyledMyMenuItemName>アカウント管理</StyledMyMenuItemName>
      </StyledMyMenuItem>

      <FullModal
        header={renderHeader()}
        content={renderContent()}
        visible={visible}
        zIndex={10000}
        bodyPadding="0"
      />
    </>
  )
}

export default AccountSettings
