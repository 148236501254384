import { Container, Text } from '@nextui-org/react'
import Link from 'next/link'
import React from 'react'
import getConfig from 'next/config'
import { Colors } from '@/constants/styles/color'
import ARROW_RIGHT from '@/assets/img/icons/Arrow_Right.svg'
import FullModal from '@/components/atoms/Dialog/FullModal'
import FullModalHeader from '@/components/molecules/Header/FullModalHeader'

interface Props {
  visible: boolean
  onClose: () => void
}

const { publicRuntimeConfig } = getConfig()

const listData: any[] = [
  {
    label: 'ヘルプ',
    link: 'https://fanme-support.zendesk.com/hc/ja',
  },
  {
    label: '利用規約',
    link: `${publicRuntimeConfig.FRONT_URL}/terms`,
  },
  {
    label: 'プライバシーポリシー',
    link: 'https://www.torihada.co.jp/privacy-policy/',
  },
  {
    label: '投稿ガイドライン',
    link: `${publicRuntimeConfig.FRONT_URL}/guidelines`,
  },
  {
    label: 'お問い合わせ',
    link: 'https://fanme-support.zendesk.com/hc/ja/requests/new',
  },
]

const HelpList = ({ visible, onClose }: Props) => {
  const renderHeader = () => <FullModalHeader title="ヘルプ・規約" onBack={onClose} />

  const renderContent = () =>
    listData.map((item, index) => {
      return (
        <Link href={item.link} key={index} rel="noopener noreferrer" target="_blank">
          <Container
            css={{
              d: 'flex',
              p: '16px 0',
              m: '0 16px',
              w: 'calc(100% - 32px)',
              h: '72px',
              ai: 'center',
              jc: 'space-between',
              borderBottom: `1px solid ${Colors.VERY_LIGHT_GRAY}`,
              maxWidth: '100%',
            }}
            key={index}
          >
            <Container
              css={{
                d: 'flex',
                flexDirection: 'column',
                maxWidth: '60%',
                margin: '0 0',
                padding: '0 8px',
              }}
            >
              <Text
                css={{
                  fontFaimly: 'Noto Sans JP',
                  fontStyle: 'normal',
                  fontSize: '13px',
                  fontWeight: '500',
                  lineHeight: '13px',
                  letterSpacing: '0em',
                  color: Colors.BLACK,
                  textAlign: 'left',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                {item.label}
              </Text>
            </Container>
            <ARROW_RIGHT width={16} height={16} />
          </Container>
        </Link>
      )
    })

  return (
    <FullModal
      header={renderHeader()}
      content={renderContent()}
      visible={visible}
      zIndex={10000}
      bodyPadding="0"
    />
  )
}

export default HelpList
