import React, { useEffect, useState } from 'react'
import getConfig from 'next/config'
import { MiniApp } from '@torihada-inc/miniapps'
import {
  SellerHistoryAccountActivityResponse,
  SellerHistoryAccountTransactionResponse,
} from '@torihada-inc/miniapps/dist/repositories/user/SellerAccountHistoryRepository'
import numeral from 'numeral'
import FullModal from '@/components/atoms/Dialog/FullModal'
import FullModalHeader from '@/components/molecules/Header/FullModalHeader'
import { useAuth } from '@/contexts/AuthContext'
import { getMiniAppInfo } from '@/pages/api/miniapp'
import { formatDateJP } from '@/libs/utils'
import { postBackend } from '@/libs/fanme_backend'

import {
  AppName,
  AppWapper,
  FieldAppIcon,
  ManagementAmount,
  ManagementAmountContainer,
  ManagementAmountDetail,
  ManagementAmountTotal,
  ManagementAmountVal,
  ManagementDetailData,
  ManagementDetailTitle,
  ManagementErrFee,
  ManagementTotalAmount,
  SellerAppName,
  StyledDetailContainer,
} from './index.style'

interface Props {
  accountId: string
  visible: boolean
  tableName: string
  tableId: number
  onClose: () => void
}

interface SellerHistoryAccountTransactionResponseWithTip
  extends SellerHistoryAccountTransactionResponse {
  tip_id: number
  sales_fee: number
}
const { publicRuntimeConfig } = getConfig()

const status = {
  LAPSE: '失効',
  RECEIVING_COMPLETED: '受取完了',
  RECEIVING_ERROR: '受取エラー',
  SUCCESS: 'success',
}

const MoneyManagementDetail = ({ accountId, visible, tableName, tableId, onClose }: Props) => {
  const { token } = useAuth()
  const [miniApp, setMiniApp] = useState<MiniApp | undefined>()
  const [activityData, setActivityData] = useState<SellerHistoryAccountActivityResponse>()
  const [transactionData, setTransactionData] =
    useState<SellerHistoryAccountTransactionResponseWithTip[]>()
  const [currentActivityData, setcurrentActivityData] =
    useState<SellerHistoryAccountActivityResponse>()

  const [currentItemDetail, setCurrenItemDetail] =
    useState<SellerHistoryAccountTransactionResponseWithTip>()

  const [goodsPrice, setGoodsPrice] = useState<number>(0)
  const [tipPrice, setTipPrice] = useState<number>(0)
  const [totalBenefit, setTotalBenefit] = useState<number>(0)

  const initMiniApp = async () => {
    const res: any = await getMiniAppInfo(accountId)

    const miniAppData = new MiniApp(publicRuntimeConfig.PAYMENT_URL, res.user_uid, token as string)
    setMiniApp(miniAppData)
  }

  const calBenefit = (transactionData: SellerHistoryAccountTransactionResponseWithTip[]) => {
    let totalGoodsPrice = 0
    let totalTipPrice = 0
    transactionData.forEach(item => {
      if (!item.tip_id) {
        totalGoodsPrice += item.amount || 0
      } else {
        totalTipPrice += item.amount || 0
      }
    })
    setGoodsPrice(totalGoodsPrice)
    setTipPrice(totalTipPrice)
  }

  const getHistoryData = async (url: string, data: any) => {
    const historyData = await postBackend(url, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token?.toString()}`,
      },
    })
    const dataRes = historyData.data
    if (tableName === 'AccountActivities') {
      setActivityData(dataRes)
    } else {
      calBenefit(dataRes)
      setTransactionData(dataRes)
      setCurrenItemDetail(dataRes[0])
    }
  }
  useEffect(() => {
    const url =
      tableName === 'AccountActivities'
        ? `${publicRuntimeConfig.PAYMENT_URL}/seller/account/history/get/activity`
        : `${publicRuntimeConfig.PAYMENT_URL}/seller/account/history/get/transaction`

    const data =
      tableName === 'AccountActivities'
        ? { account_activity_id: tableId }
        : { transaction_id: tableId }

    if (tableName && tableId) {
      getHistoryData(url, data)
    }
  }, [tableName, tableId])

  // useEffect(() => {
  //   if (tableName == 'AccountActivities') {
  //     setActivityData(historyData)
  //   } else {
  //     // TODO miniapp経由で取得せず、直接payment-apiを叩く
  //     // TODO 現状だと1つのTransactionに対し一つのDetail(sellerAppItem)し取得していない -> 複数Detail&Tipsのリストを返すようAPIを修正
  //     setTransactionData(historyData)
  //   }
  // }, [tableName])

  useEffect(() => {
    if (token && visible) {
      initMiniApp()
    }
  }, [accountId, token, visible])

  const renderHeader = () => <FullModalHeader title="詳細履歴" onBack={onClose} />

  const renderContent = () => (
    <>
      {tableName === 'AccountActivities' ? (
        <>
          <StyledDetailContainer>
            <ManagementDetailTitle>受取申請日</ManagementDetailTitle>
            <ManagementDetailData>{formatDateJP(activityData?.created_at, 9)}</ManagementDetailData>
          </StyledDetailContainer>

          <StyledDetailContainer>
            <ManagementDetailTitle>受取方法</ManagementDetailTitle>
            <ManagementDetailData>銀行振込</ManagementDetailData>
          </StyledDetailContainer>

          <StyledDetailContainer>
            <ManagementDetailTitle>ステータス</ManagementDetailTitle>
            <ManagementDetailData isError={activityData?.status !== status.RECEIVING_COMPLETED}>
              {activityData?.status}
            </ManagementDetailData>
          </StyledDetailContainer>

          {activityData?.status === status.LAPSE ? ( //失効の場合
            <>
              <StyledDetailContainer>
                <ManagementDetailTitle>失効日</ManagementDetailTitle>
                <ManagementDetailData>
                  {formatDateJP(activityData?.updated_at, 9)}
                </ManagementDetailData>
              </StyledDetailContainer>

              <StyledDetailContainer>
                <ManagementDetailTitle>失効金額</ManagementDetailTitle>
                <ManagementErrFee>
                  -￥{numeral(activityData.expired_sales_amount).format('0,0')}
                </ManagementErrFee>
              </StyledDetailContainer>
            </>
          ) : (
            activityData?.status === status.RECEIVING_ERROR ||
            (activityData?.status === status.RECEIVING_COMPLETED && (
              <>
                {activityData?.status === status.RECEIVING_COMPLETED && (
                  <StyledDetailContainer>
                    <ManagementDetailTitle>振込金額</ManagementDetailTitle>

                    <ManagementAmountContainer>
                      <ManagementAmountTotal>
                        <ManagementAmount>合計</ManagementAmount>
                        <ManagementTotalAmount>
                          ¥{numeral(activityData?.received_sales_amount).format('0,0')}
                        </ManagementTotalAmount>
                      </ManagementAmountTotal>

                      <ManagementAmountDetail>
                        <ManagementAmount isGray>売上金</ManagementAmount>
                        <ManagementAmountVal>
                          ¥{numeral(activityData?.transfer_amount).format('0,0')}
                        </ManagementAmountVal>
                      </ManagementAmountDetail>

                      <ManagementAmountDetail>
                        <ManagementAmount isGray>振込手数料</ManagementAmount>
                        <ManagementAmountVal isRed>
                          -¥{numeral(activityData?.transfer_fee).format('0,0')}
                        </ManagementAmountVal>
                      </ManagementAmountDetail>
                    </ManagementAmountContainer>
                  </StyledDetailContainer>
                )}

                <StyledDetailContainer>
                  <ManagementDetailTitle>振込日</ManagementDetailTitle>
                  <ManagementDetailData>
                    {formatDateJP(activityData?.updated_at, 9)}
                  </ManagementDetailData>
                </StyledDetailContainer>

                {activityData?.status === status.RECEIVING_ERROR && (
                  <StyledDetailContainer>
                    <ManagementDetailTitle>振込エラー手数料</ManagementDetailTitle>
                    <ManagementErrFee>-¥220</ManagementErrFee>
                  </StyledDetailContainer>
                )}
              </>
            ))
          )}
        </>
      ) : (
        <>
          <StyledDetailContainer>
            <ManagementDetailTitle>APPS</ManagementDetailTitle>
            <AppWapper className="max-w-[60%]">
              <AppName>{currentItemDetail?.app_name}</AppName>
              <FieldAppIcon width="40" height="40" src={currentItemDetail?.app_icon} />
            </AppWapper>
          </StyledDetailContainer>

          <StyledDetailContainer>
            <ManagementDetailTitle>商品名</ManagementDetailTitle>
            <div className="max-w-[60%]">
              {transactionData?.map((item, index) => {
                return (
                  <AppWapper key={index}>
                    <div>
                      <SellerAppName>{item?.seller_app_name}</SellerAppName>
                      <AppName>{item.tip_id ? 'チップ' : item?.seller_app_item_name}</AppName>
                    </div>

                    {item.tip_id ? (
                      <div className="w-10 h-10" />
                    ) : (
                      <FieldAppIcon width="40" height="40" src={item?.seller_app_item_icon} />
                    )}
                  </AppWapper>
                )
              })}
            </div>
          </StyledDetailContainer>

          <StyledDetailContainer>
            <ManagementDetailTitle>ステータス</ManagementDetailTitle>
            <ManagementDetailData className="font-sansJP">
              {currentItemDetail?.status === status.SUCCESS ? '購入済み' : '購入失敗'}
            </ManagementDetailData>
          </StyledDetailContainer>

          <StyledDetailContainer>
            <ManagementDetailTitle>購入日</ManagementDetailTitle>
            <ManagementDetailData fontSize={13} className="font-Montserrat">
              {formatDateJP(currentItemDetail?.updated_at, 9)}
            </ManagementDetailData>
          </StyledDetailContainer>

          <StyledDetailContainer>
            <ManagementDetailTitle>売上金</ManagementDetailTitle>
            <ManagementAmountContainer>
              <ManagementAmountTotal>
                <ManagementAmount>利益</ManagementAmount>
                <ManagementTotalAmount>
                  ¥{numeral(currentItemDetail?.seller_amount).format('0,0')}
                  <span>(税込)</span>
                </ManagementTotalAmount>
              </ManagementAmountTotal>
              <ManagementAmountDetail>
                <ManagementAmount isGray>商品価格</ManagementAmount>
                <ManagementAmountVal>¥{numeral(goodsPrice).format('0,0')}</ManagementAmountVal>
              </ManagementAmountDetail>
              {tipPrice > 0 && (
                <ManagementAmountDetail>
                  <ManagementAmount isGray>チップ</ManagementAmount>
                  <ManagementAmountVal>¥{numeral(tipPrice).format('0,0')}</ManagementAmountVal>
                </ManagementAmountDetail>
              )}
              <ManagementAmountDetail>
                <ManagementAmount isGray>販売手数料</ManagementAmount>
                <ManagementAmountVal isRed>
                  -¥{numeral(currentItemDetail?.sales_fee).format('0,0')}
                </ManagementAmountVal>
              </ManagementAmountDetail>
            </ManagementAmountContainer>
          </StyledDetailContainer>
        </>
      )}
    </>
  )

  return (
    <FullModal
      header={renderHeader()}
      content={renderContent()}
      visible={visible}
      zIndex={10000}
      bodyPadding="0 16px"
    />
  )
}

export default MoneyManagementDetail
