import styled from '@emotion/styled'
import React, { HTMLAttributes } from 'react'
import { Colors, ContextType, contextTypeColor } from '@/fanmeUi/constant'

type FmeMessageProps = {
  outline?: boolean
  type: ContextType
  textColor?: string
} & WithChildren &
  HTMLAttributes<HTMLDivElement>

const MessageWrap = styled.div<FmeMessageProps>`
  border: ${({ outline, type }) =>
    outline ? '1px solid ' + contextTypeColor[type].color : 'none'};
  background-color: ${({ type, outline }) =>
    outline ? 'transparent' : contextTypeColor[type].color};
  position: relative;
  > div {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    width: 100%;
    height: 100%;
    padding: 12px 12px;
    color: ${({ type, textColor, outline }) =>
      textColor || (outline ? contextTypeColor[type].color : Colors.WHITE)};
  }
`
const FmeMessage = ({ children, type = 'normal', ...props }: FmeMessageProps) => {
  return (
    <MessageWrap {...props} type={type}>
      <div>{children}</div>
    </MessageWrap>
  )
}

export { FmeMessage }
