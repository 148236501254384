import React, { ReactNode } from 'react'
import FanmeDialog from '@/components/molecules/Alert/index'
import { Colors } from '@/constants/styles/color'

interface Props {
  message: ReactNode
  actionMessage: string
  action: () => void
  close: (v: ActionState) => void
  visible: boolean
  checkIconOn?: boolean
}

type ActionState = {
  message: ReactNode
  actionMessage: string
  action: () => void
  close: (v: ActionState) => void
  visible: boolean
  checkIconOn?: boolean
}

const ActionDialog = ({ message, actionMessage, action, close, visible, checkIconOn }: Props) => {
  const closeArg: ActionState = {
    message: message,
    actionMessage: actionMessage,
    action: action,
    close: close,
    visible: false,
  }
  return (
    <FanmeDialog
      text={message}
      visible={visible}
      action={actionMessage}
      onClose={() => close(closeArg)}
      onAction={action}
      actionButtonColor={Colors.BLACK}
      actionButtonTextColor={Colors.WHITE}
      closeOn
      checkIconOn={checkIconOn !== undefined ? checkIconOn : true}
    />
  )
}

export default ActionDialog
export type { ActionState }
