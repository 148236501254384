import React, { useEffect, useState } from 'react'
import 'cleave.js/dist/addons/cleave-phone.jp'
import Cleave from 'cleave.js/react'
import { Colors } from '@/constants/styles/color'
import HalfModal from '@/components/atoms/Dialog/HalfModal'
import HalfModalHeader from '@/components/molecules/Header/HalfModalHeader'
import { createAddressInfo } from '@/pages/api/payment'
import { FmeSelectInput } from '@/fanmeUi/form/input'
import { prefectureJp } from '@/fanmeUi/constants/prefecture_jp'
import { ga4AddShippingInfo, ga4SellerAppItemsToItems, ga4SumItemsPrice } from '@/libs/ga_event'
import {
  StyledAddCardButton,
  StyledAny,
  StyledDialogBody,
  StyledDialogBodyItem,
  StyledDialogText,
  StyledErrMsg,
  StyledForm,
  StyledFormHalfInput,
  StyledFormInput,
  StyledFormItem,
  StyledFormSelect,
  StyledHighlightDescription,
  StyledPostalCodeButton,
  StyledRequired,
  StyledTitle,
} from './index.style'

interface Props {
  backTo: () => void
  addressInfo?: AddressInfo | null
  sellerAppItems?: any
  sellerAppItemPrices?: any
}

export interface AddressInfo {
  attributes: {
    type: string
  }
  FanmeUserId__c: string
  Id: string
  Name__c: string
  NameKana__c: string
  PostalCode__c: string
  Prefecture__c: string
  City__c: string
  Street__c: string
  PhoneNumber__c: string
  Building__c?: string
}

const AddAddress = ({ backTo, addressInfo, sellerAppItems, sellerAppItemPrices }: Props) => {
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [firstNameKana, setFirstNameKana] = useState<string>('')
  const [lastNameKana, setLastNameKana] = useState<string>('')
  const [postalCode, setPostalCode] = useState<string>('')
  const [prefecture, setPrefecture] = useState<string>('')
  const [city, setCity] = useState<string>('')
  const [street, setStreet] = useState<string>('')
  const [building, setBuilding] = useState<string | null>('')
  const [phoneNumber, setPhoneNumber] = useState<string>('')

  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true)

  const [nameErrMsg, setNameErrMsg] = useState<string>('')
  const [nameKanaErrMsg, setNameKanaErrMsg] = useState<string>('')
  const [postalCodeErrMsg, setPostalCodeErrMsg] = useState<string>('')
  const [prefectureErrMsg, setPrefectureErrMsg] = useState<string>('')
  const [cityErrMsg, setCityErrMsg] = useState<string>('')
  const [streetErrMsg, setStreetErrMsg] = useState<string>('')
  const [phoneNumberErrMsg, setPhoneNumberErrMsg] = useState<string>('')

  const addAddressInfo = async () => {
    try {
      const name = lastName + '　' + firstName
      const nameKana = lastNameKana + '　' + firstNameKana
      if (!prefecture) setPrefecture('北海道')
      if (!name || !nameKana || !postalCode || !prefecture || !city || !street || !phoneNumber)
        return
      const data = {
        id:
          addressInfo && Array.isArray(addressInfo) && addressInfo.length > 0
            ? addressInfo[0].Id
            : null,
        name: name,
        name_kana: nameKana,
        postal_code: postalCode,
        prefecture: prefecture,
        city: city,
        street: street,
        building: building || null,
        phone_number: phoneNumber,
      }
      const address = await createAddressInfo(data)
      if (address.status === 200) {
        backTo()
      }
    } catch (e) {
      console.log(e)
    } finally {
      // 商品購入する時のGAイベント用、 アカウント管理画面ではうごかない
      if (sellerAppItems && sellerAppItemPrices) {
        ga4AddShippingInfo(
          ga4SumItemsPrice(sellerAppItemPrices),
          ga4SellerAppItemsToItems(sellerAppItems, sellerAppItemPrices),
        )
      }
    }
  }

  const changeLastName = (name: string) => {
    if (!name) {
      setNameErrMsg('必須です')
      setLastName('')
      return
    }
    setLastName(name)
    setNameErrMsg('')
  }

  const changeFirstName = (name: string) => {
    if (!name) {
      setNameErrMsg('必須です')
      setFirstName('')
      return
    }
    setFirstName(name)
    setNameErrMsg('')
  }
  const changeLastNameKana = (nameKana: string) => {
    if (!nameKana) {
      setNameKanaErrMsg('必須です')
      setLastNameKana('')
      return
    }
    setLastNameKana(nameKana)
    setNameKanaErrMsg('')
  }

  const changeFirstNameKana = (nameKana: string) => {
    if (!nameKana) {
      setNameKanaErrMsg('必須です')
      setFirstNameKana('')
      return
    }
    setFirstNameKana(nameKana)
    setNameKanaErrMsg('')
  }

  const changePostalCode = (postalCode: string) => {
    //-が入力されている場合、-を削除する
    if (postalCode.includes('-') && postalCode.length === 8) {
      postalCode = postalCode.replace(/-/g, '')
      setPostalCode(postalCode)
      return
    }

    if (!postalCode) {
      setPostalCodeErrMsg('必須です')
      setPostalCode('')
      return
    }

    setPostalCode(postalCode)
    setPostalCodeErrMsg('')
  }

  const changePrefecture = (prefecture: string) => {
    setPrefecture(prefecture)
    setPrefectureErrMsg('')
  }

  const changeCity = (city: string) => {
    if (!city) {
      setCityErrMsg('必須です')
      setCity('')
      return
    }
    setCity(city)
    setCityErrMsg('')
  }

  const changeStreet = (street: string) => {
    if (!street) {
      setStreetErrMsg('必須です')
      setStreet('')
      return
    }
    setStreet(street)
    setStreetErrMsg('')
  }

  const changeBuilding = (building?: string) => {
    if (!building) {
      setBuilding('')
      return
    }
    setBuilding(building)
  }

  const changePhoneNumber = (phoneNumber: string) => {
    setPhoneNumber(phoneNumber)

    if (!phoneNumber) {
      setPhoneNumberErrMsg('必須です')
      return
    }

    // 電話番号バリデーション
    if (!/^0\d{1,4}-\d{1,4}-\d{3,4}$/.test(phoneNumber)) {
      setPhoneNumberErrMsg('不正な値です')
      return
    }

    setPhoneNumberErrMsg('')
  }

  const suggestAddress = async () => {
    try {
      const res = await (
        await fetch(`/api/v1/kawa/address/suggestion/jpn/postal-code/${postalCode}`)
      ).json()

      if (res.addr_lv1 && res.addr_lv2 && res.addr_lv3) {
        setPrefecture(res.addr_lv1)
        setCity(res.addr_lv2)
        if (res.addr_lv3 !== '以下に掲載がない場合') {
          setStreet(res.addr_lv3)
        }
      } else {
        setPostalCodeErrMsg('不正な値です')
      }
    } catch (error) {
      // 失敗時の処理を追加、例えばエラーメッセージの設定や他のアクションの実行など
      setPostalCodeErrMsg('住所検索に失敗しました')
    }
  }

  useEffect(() => {
    if (addressInfo && Array.isArray(addressInfo) && addressInfo.length > 0) {
      const firstAddress = addressInfo[0]
      setName(firstAddress.Name__c || '')
      setNameKana(firstAddress.NameKana__c || '')
      setPostalCode(firstAddress.PostalCode__c || '')
      setPrefecture(firstAddress.Prefecture__c || '')
      setCity(firstAddress.City__c || '')
      setStreet(firstAddress.Street__c || '')
      setBuilding(firstAddress?.Building__c || '')
      setPhoneNumber(firstAddress.PhoneNumber__c || '')
    }
  }, [addressInfo])

  useEffect(() => {
    if (prefecture !== '') {
      setPrefectureErrMsg('')
    }
    if (city !== '') {
      setCityErrMsg('')
    }
    if (street !== '') {
      setStreetErrMsg('')
    }
  }, [prefecture, city, street])

  const setName = (name: string) => {
    const nameArray = name?.split(/\s+/)
    setLastName(nameArray[0] || '')
    setFirstName(nameArray[1] || '')
  }

  const setNameKana = (nameKana: string) => {
    const nameKanaArray = nameKana?.split(/\s+/)
    setLastNameKana(nameKanaArray[0] || '')
    setFirstNameKana(nameKanaArray[1] || '')
  }

  useEffect(() => {
    if (
      !firstName ||
      !lastName ||
      !firstNameKana ||
      !lastNameKana ||
      !postalCode ||
      postalCode.length !== 7 ||
      !city ||
      !street ||
      !phoneNumber ||
      phoneNumberErrMsg
    ) {
      setButtonDisabled(true)
      return
    }
    setButtonDisabled(false)
  }, [
    firstName,
    lastName,
    firstNameKana,
    lastNameKana,
    postalCode,
    city,
    street,
    phoneNumber,
    phoneNumberErrMsg,
  ])

  const renderHeader = () => (
    <HalfModalHeader
      title="配送先情報"
      background={Colors.WHITE}
      textColor={Colors.BLACK}
      onBack={backTo}
    />
  )

  const renderContent = () => (
    <StyledDialogBody>
      <StyledDialogBodyItem>
        <StyledDialogText>お届け先の住所を入力してください</StyledDialogText>

        <StyledForm>
          <StyledFormItem>
            <StyledTitle>名前(全角)</StyledTitle>
            <StyledRequired>*必須</StyledRequired>
          </StyledFormItem>
          <StyledFormHalfInput isExistErrMsg={nameErrMsg ? true : false}>
            <StyledFormSelect>
              <input
                name="last_name"
                placeholder="姓"
                type="text"
                autoCapitalize="off"
                onChange={e => changeLastName(e.target.value)}
                value={lastName}
              />
              <input
                name="first_name"
                placeholder="名"
                type="text"
                autoCapitalize="off"
                onChange={e => changeFirstName(e.target.value)}
                value={firstName}
              />
            </StyledFormSelect>
            {nameErrMsg && <StyledErrMsg>{nameErrMsg}</StyledErrMsg>}
          </StyledFormHalfInput>
        </StyledForm>

        <StyledForm>
          <StyledFormItem>
            <StyledTitle>全角カナ</StyledTitle>
            <StyledRequired>*必須</StyledRequired>
          </StyledFormItem>
          <StyledFormHalfInput isExistErrMsg={nameKanaErrMsg ? true : false}>
            <StyledFormSelect>
              <input
                name="last_name_kana"
                placeholder="セイ"
                type="text"
                autoCapitalize="off"
                onChange={e => changeLastNameKana(e.target.value)}
                value={lastNameKana}
              />
              <input
                name="last_name_kana"
                placeholder="メイ"
                type="text"
                autoCapitalize="off"
                onChange={e => changeFirstNameKana(e.target.value)}
                value={firstNameKana}
              />
            </StyledFormSelect>
            {nameKanaErrMsg && <StyledErrMsg>{nameKanaErrMsg}</StyledErrMsg>}
          </StyledFormHalfInput>
        </StyledForm>

        <StyledForm>
          <StyledFormItem>
            <StyledTitle>郵便番号</StyledTitle>
            <StyledRequired>*必須</StyledRequired>
            <StyledHighlightDescription className="ml-2 font-sansJP">
              ※一部、住所が検出しきれない場合があります
            </StyledHighlightDescription>
          </StyledFormItem>
          <StyledFormHalfInput isExistErrMsg={postalCodeErrMsg ? true : false}>
            <StyledFormSelect>
              <input
                name="postalCode"
                placeholder="1234567"
                type="text"
                autoCapitalize="off"
                pattern="[0-9]*"
                onChange={e => changePostalCode(e.target.value)}
                value={postalCode}
                maxLength={8}
              />
              <StyledPostalCodeButton disabled={postalCode?.length !== 7} onClick={suggestAddress}>
                住所検索
              </StyledPostalCodeButton>
            </StyledFormSelect>
            {postalCodeErrMsg && <StyledErrMsg>{postalCodeErrMsg}</StyledErrMsg>}
          </StyledFormHalfInput>
        </StyledForm>

        <StyledForm>
          <StyledFormItem>
            <StyledTitle>都道府県</StyledTitle>
            <StyledRequired>*必須</StyledRequired>
          </StyledFormItem>
          <StyledFormInput isExistErrMsg={prefectureErrMsg ? true : false}>
            <FmeSelectInput
              name="prefecture"
              onChange={e => changePrefecture(e.target.value)}
              value={prefecture}
              options={Object.values(prefectureJp).map(v => {
                return {
                  value: v,
                }
              })}
            />
            {prefectureErrMsg && <StyledErrMsg>{prefectureErrMsg}</StyledErrMsg>}
          </StyledFormInput>
        </StyledForm>

        <StyledForm>
          <StyledFormItem>
            <StyledTitle>市区町村</StyledTitle>
            <StyledRequired>*必須</StyledRequired>
          </StyledFormItem>
          <StyledFormInput isExistErrMsg={cityErrMsg ? true : false}>
            <input
              name="city"
              placeholder="渋谷区"
              type="text"
              autoCapitalize="off"
              onChange={e => changeCity(e.target.value)}
              value={city}
            />
            {cityErrMsg && <StyledErrMsg>{cityErrMsg}</StyledErrMsg>}
          </StyledFormInput>
        </StyledForm>

        <StyledForm>
          <StyledFormItem>
            <StyledTitle>町名・番地</StyledTitle>
            <StyledRequired>*必須</StyledRequired>
          </StyledFormItem>
          <StyledFormInput isExistErrMsg>
            <input
              name="street"
              placeholder="道玄坂2-25-12"
              type="text"
              autoCapitalize="off"
              onChange={e => changeStreet(e.target.value)}
              value={street}
            />
            {streetErrMsg && <StyledErrMsg>{streetErrMsg}</StyledErrMsg>}
          </StyledFormInput>
        </StyledForm>

        <StyledForm>
          <StyledFormItem>
            <StyledTitle>ビル、マンション名</StyledTitle>
            <StyledAny>*任意</StyledAny>
          </StyledFormItem>
          <StyledFormInput isExistErrMsg>
            <input
              name="building"
              placeholder="⚪︎⚪︎マンション101号室"
              type="text"
              autoCapitalize="off"
              onChange={e => changeBuilding(e.target.value)}
              value={building || ''}
            />
          </StyledFormInput>
          <StyledHighlightDescription className="mt-2 font-sansJP">
            ※住所が正しく入力されていない場合、届かない恐れがあります。
          </StyledHighlightDescription>
        </StyledForm>

        <StyledForm>
          <StyledFormItem>
            <StyledTitle>電話番号</StyledTitle>
            <StyledRequired>*必須</StyledRequired>
          </StyledFormItem>
          <StyledFormInput isExistErrMsg={phoneNumberErrMsg ? true : false}>
            <Cleave
              placeholder="090-1234-5678"
              options={{
                phone: true,
                phoneRegionCode: 'JP',
                delimiter: '-',
              }}
              onChange={e => changePhoneNumber(e.target.value)}
              pattern="[0-9]*"
              value={phoneNumber}
            />
            {phoneNumberErrMsg && <StyledErrMsg>{phoneNumberErrMsg}</StyledErrMsg>}
          </StyledFormInput>
        </StyledForm>
      </StyledDialogBodyItem>
      <StyledAddCardButton disabled={buttonDisabled} onClick={addAddressInfo}>
        登録する
      </StyledAddCardButton>
    </StyledDialogBody>
  )

  return <HalfModal header={renderHeader()} content={renderContent()} top={46}></HalfModal>
}

export default AddAddress
