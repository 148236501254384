import styled from '@emotion/styled'
import { Colors } from '@/constants/styles/color'

const StyledDialogHeader = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0px 16px;
  color: ${Colors.BLACK};
`

const StyledArrowBackIcon = styled.div`
  width: 24px;
  height: 24px;
  img {
    width: 24px;
    height: 24px;
  }
`

const StyledDialogHeaderText = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
`

const StyledCloseIcon = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 24px;
    height: 24px;
  }
`

const StyledDialogBody = styled.div`
  padding: 24px 16px 96px 16px;
  height: calc(100% - 47px);
  overflow-y: scroll;
  color: ${Colors.BLACK};
`

const StyledDialogBodyItem = styled.div<{ paymentMethod: string }>`
  width: 100%;
  padding: ${props => (props.paymentMethod === 'card' ? '16px 0' : '0')};
  align-items: center;
  .item-value {
    display: flex;
    align-items: center;
    justify-content: right;
    gap: 8px;
    margin-left: ${props => (props.paymentMethod === 'GooglePay' ? '4px' : '16px')};
    .card-info {
      display: flex;
      width: 100%;
      .detail {
        display: grid;
        align-items: center;
        margin-left: 12px;
        .numbers {
          color: ${Colors.BLACK};
          font-weight: 500;
          font-size: 12px;
          line-height: 12px;
        }
        .date {
          font-weight: 400;
          font-size: 11px;
          line-height: 11px;
          color: ${Colors.GRAY};
        }
        .title {
          color: ${Colors.BLACK};
          font-weight: 500;
          font-size: 12px;
          line-height: 12px;
        }
      }
    }
  }
`

const StyledAddCardButton = styled.button`
  background: ${Colors.WHITE};
  border: 1px solid ${Colors.BLACK};
  border-radius: 24px;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Colors.BLACK};
  height: 48px;
  width: 100%;
  max-width: 328px;
  margin: 24px auto 40px;
  position: relative;
  img {
    position: absolute;
    left: 16px;
  }
`

const StyledPurchaseTitle = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
`

export {
  StyledDialogHeader,
  StyledArrowBackIcon,
  StyledDialogHeaderText,
  StyledCloseIcon,
  StyledDialogBody,
  StyledDialogBodyItem,
  StyledAddCardButton,
  StyledPurchaseTitle,
}
