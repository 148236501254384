import React, {
  createRef,
  HTMLAttributes,
  InputHTMLAttributes,
  SelectHTMLAttributes,
  TextareaHTMLAttributes,
  useEffect,
} from 'react'
import styled from '@emotion/styled'
import { Colors } from '@/fanmeUi/constants/style'
import { FmePaddingContainer } from '@/fanmeUi/layouts/container'
import { TextSpan } from '@/fanmeUi/shared/base'

const Label = styled.label<any>`
  font-size: 11px;
  font-weight: 500;
  padding: 0 0 8px 0;
  display: inline-block;
  color: ${({ color }) => `${color || Colors.BLACK}`};
`

const InputWrap = styled.div<{ isFullWidth: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-end;
  flex-wrap: wrap;
  flex-grow: ${({ isFullWidth }) => (isFullWidth ? 1 : 0)};
`

const LabelRightWrap = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`
export const RequiredLabel = styled.span`
  font-size: inherit;
  color: ${Colors.ORANGE};
`
export const OptionalLabel = styled.span`
  font-size: inherit;
  color: ${Colors.LIGHT_GRAY};
`

const InputMsg = styled.span<{ invalid?: boolean }>`
  color: ${({ invalid }) => (invalid ? Colors.ERROR : Colors.GRAY)};
  font-family: 'Noto Sans JP';
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
  margin-top: 4px;
`

const Input = styled.input<InputHTMLAttributes<any> & { isFullwidth?: boolean; invalid?: boolean }>`
  background-color: ${Colors.ULTRA_LIGHT_GRAY};
  border-radius: 8px;
  height: 48px;
  border: ${({ invalid }) => (invalid ? `1px solid ${Colors.ERROR}` : 'none')};
  padding: 16px;
  color: ${Colors.BLACK};
  width: ${({ isFullwidth }) => (isFullwidth ? '100%' : 'auto')};
  &::placeholder {
    color: ${Colors.LIGHT_GRAY};
  }
`

const Textarea = styled.textarea<
  TextareaHTMLAttributes<any> & { isFullwidth?: boolean; invalid: boolean }
>`
  background-color: ${Colors.ULTRA_LIGHT_GRAY};
  border-radius: 8px;
  min-height: 48px;
  height: auto;
  resize: none;
  overflow: hidden;
  border: none;
  padding: 16px;
  color: ${Colors.BLACK};
  width: ${({ isFullwidth }) => (isFullwidth ? '100%' : 'auto')};
  &::placeholder {
    color: ${Colors.LIGHT_GRAY};
  }
`

const Select = styled.select<
  SelectHTMLAttributes<any> & { isFullwidth?: boolean; invalid?: boolean }
>`
  display: block;
  background-color: ${Colors.ULTRA_LIGHT_GRAY};
  border-radius: 8px;
  height: 48px;
  border: ${({ invalid }) => (invalid ? `1px solid ${Colors.ERROR}` : 'none')};
  padding: 16px;
  color: ${Colors.BLACK};
  width: ${({ isFullwidth }) => (isFullwidth ? '100%' : 'auto')};
  &::placeholder {
    color: ${Colors.LIGHT_GRAY};
  }
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  line-height: 1;
`

const InputGroupItem = styled.div<{ fullwidth?: boolean }>`
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0 6px;
`

type FmeInputProps = {
  name: string
  isFullwidth?: boolean
  multiline?: boolean
  invalid?: boolean
  message?: string
} & InputHTMLAttributes<any>
const FmeInput = ({
  name,
  isFullwidth = false,
  invalid = false,
  message = '',
  multiline = false,
  ...props
}: FmeInputProps) => {
  const inputRef = createRef<HTMLTextAreaElement>()

  const updateHeight = () => {
    if (multiline && inputRef.current) {
      inputRef.current.style.height = '48px'
      inputRef.current.style.height = inputRef.current.scrollHeight + 'px'
    }
  }

  useEffect(() => {
    updateHeight()
  }, [])

  return (
    <InputWrap isFullWidth={isFullwidth}>
      {multiline ? (
        <Textarea
          {...props}
          isFullwidth={isFullwidth}
          name={name}
          invalid={invalid}
          id={`input-${name}`}
          ref={inputRef}
          onChange={(e: any) => {
            updateHeight()
            if (props.onChange) {
              props.onChange(e)
            }
          }}
          autoCapitalize="off"
        />
      ) : (
        <Input
          {...props}
          invalid={invalid}
          isFullwidth={isFullwidth}
          name={name}
          id={`input-${name}`}
          autoCapitalize="off"
        />
      )}
      {message && <InputMsg invalid={invalid}>{message}</InputMsg>}
    </InputWrap>
  )
}

type FmeInputGroupProps = {
  label?: string
  labelRight?: any
  afterLabel?: any
  requiredLabel?: boolean
  optionalLabel?: boolean
  invalid?: boolean
  message?: string
} & WithChildren
const FmeInputGroup = ({
  children,
  label,
  labelRight,
  afterLabel,
  requiredLabel = false,
  optionalLabel = false,
  invalid = false,
  message = '',
}: FmeInputGroupProps) => {
  return (
    <FmePaddingContainer bottom={18} style={{ position: 'relative' }}>
      {label && (
        <Label htmlFor={`input-name`}>
          {label}
          {requiredLabel ? (
            <>
              <RequiredLabel>*必須</RequiredLabel>
              {afterLabel}
            </>
          ) : (
            optionalLabel && <OptionalLabel>*任意</OptionalLabel>
          )}
        </Label>
      )}
      {labelRight && <LabelRightWrap>{labelRight}</LabelRightWrap>}
      <div style={{ display: 'flex', alignItems: 'flex-start' }}>{children}</div>
      {message && <InputMsg invalid={invalid}>{message}</InputMsg>}
    </FmePaddingContainer>
  )
}
type FmeInputGroupItemProps = {
  fullwidth?: boolean
} & WithChildren
const FmeInputGroupItem = ({ children, ...props }: FmeInputGroupItemProps) => {
  return <InputGroupItem {...props}>{children}</InputGroupItem>
}

type FmeSelectInputOption = {
  value: string
  label?: string
}

type FmeSelectInputProps = {
  options: FmeSelectInputOption[]
  invalid?: boolean
  message?: string
} & SelectHTMLAttributes<any>
const FmeSelectInput = ({
  options,
  invalid = false,
  message = '',
  ...props
}: FmeSelectInputProps) => {
  return (
    <>
      <Select {...props} invalid={invalid}>
        {options.map((v, i) => {
          return (
            <option key={`opt-${props.name}-${i}`} value={v.value}>
              {v.label || v.value}
            </option>
          )
        })}
      </Select>
      {message && <InputMsg invalid={invalid}>{message}</InputMsg>}
    </>
  )
}

type FmeInputConfirmProps = {
  label?: string
} & WithChildren
const FmeInputConfirm = ({ children, label }: FmeInputConfirmProps) => {
  return (
    <FmePaddingContainer bottom={18}>
      {label && <Label color={Colors.LIGHT_GRAY}>{label}</Label>}
      <div style={{ borderBottom: `1px solid ${Colors.ULTRA_LIGHT_GRAY}`, paddingBottom: 4 }}>
        {children}
      </div>
    </FmePaddingContainer>
  )
}

type FmeMaxLengthLabelProps = {
  maxLength: number
  current: number
} & HTMLAttributes<HTMLSpanElement>
const FmeMaxLengthLabel = ({ maxLength, current, ...props }: FmeMaxLengthLabelProps) => {
  return (
    <TextSpan {...props} type={current > maxLength * 0.8 ? 'danger' : 'normal'}>
      {current}/{maxLength}
    </TextSpan>
  )
}

const Switcher = styled.div<{ isOn: boolean }>`
  position: relative;
  background-color: ${props => (props.isOn ? Colors.FANME_YELLOW : Colors.LIGHT_GRAY)};
  border-radius: 14px;
  width: 56px;
  height: 28px;
  &:after {
    content: '';
    position: absolute;
    left: ${props => (props.isOn ? '28px' : '0')};
    top: 0;
    margin: 3px;
    display: block;
    width: 22px;
    height: 22px;
    border-radius: 12px;
    background-color: white;
  }
`

type FmeSwitchProps = {
  isOn: boolean
  callback: (on: boolean) => void
} & WithChildren
const FmeSwitch = ({ children, isOn, callback }: FmeSwitchProps) => {
  return (
    <div style={{ display: 'flex' }}>
      <div style={{ flex: 1 }}>{children}</div>
      <Switcher
        isOn={isOn}
        onClick={() => {
          callback(!isOn)
        }}
      />
    </div>
  )
}

export {
  FmeInput,
  FmeSwitch,
  FmeInputGroup,
  FmeInputGroupItem,
  FmeInputConfirm,
  FmeSelectInput,
  FmeMaxLengthLabel,
}
