import React, { useState } from 'react'
import RejcetIcon from '@/assets/img/icons/Reject.svg'
import HelpList from '../HelpList'
import { StyledMyMenuItem, StyledMyMenuItemName } from '../index.style'

const Help = () => {
  const [visible, setVisible] = useState(false)

  return (
    <>
      <StyledMyMenuItem onClick={() => setVisible(true)}>
        <RejcetIcon width={20} height={20} />
        <StyledMyMenuItemName>ヘルプ・規約</StyledMyMenuItemName>
      </StyledMyMenuItem>
      <HelpList visible={visible} onClose={() => setVisible(false)} />
    </>
  )
}

export default Help
