import React from 'react'
import Router from 'next/router'
import DashboardIcon from '@/assets/img/icons/Dashboard.svg'
import { StyledMyMenuItem, StyledMyMenuItemName } from '../index.style'

const Dashboard = () => {
  const handleClick = () => {
    Router.push('/dashboard')
  }
  return (
    <StyledMyMenuItem onClick={handleClick} className="cursor-pointer" width="144px">
      <DashboardIcon width={20} height={20} />
      <StyledMyMenuItemName>ダッシュボード</StyledMyMenuItemName>
    </StyledMyMenuItem>
  )
}

export default Dashboard
