import React, { useState } from 'react'
import FanmeLoading from '@/components/atoms/FanmeLoading'
import { Colors } from '@/constants/styles/color'
import FullModal from '@/components/atoms/Dialog/FullModal'
import FullModalHeader from '@/components/molecules/Header/FullModalHeader'
import { useFanmeBackendPost } from '@/libs/fanme_backend'

import {
  StyledBlogBottom,
  StyledBlogContent,
  StyledBlogDate,
  StyledBlogImg,
  StyledBlogLabel,
  StyledBlogTitle,
  StyledWrapper,
  StyledWrapperLabel,
} from './index.style'

interface Props {
  id: number
  visible: boolean
  onClose: () => void
  title: string
  description: string
  content: string
  image_url: string
  label: number
  priority: number
  published_at: string
  published_at_hh_mm: string
}

const BlogArticleDetail = ({
  id,
  visible,
  onClose,
  title,
  description,
  content,
  image_url,
  label,
  published_at,
  published_at_hh_mm,
}: Props) => {
  const [loaded, setLoaded] = useState(false)
  const onCloseModal = () => {
    onClose()
  }
  const getLabelInfo = (label: number) => {
    if (label === 0) return ' お知らせ '
    else if (label === 1) return ' 重要 '
    else if (label === 2) return ' イベント '
    else if (label === 3) return ' その他 '
  }
  const getLabelColor = (label: number) => {
    if (label == 0) return Colors.ORANGE
    else if (label === 1) return Colors.RED
    else if (label === 2) return Colors.LIGHT_BLUE
    else if (label === 3) return Colors.DARK_GRAY
  }
  const renderHeader = () => <FullModalHeader title="お知らせ詳細" onBack={onCloseModal} />
  const renderContent = () => (
    <>
      <StyledWrapper>
        <StyledBlogTitle>{title}</StyledBlogTitle>
        <StyledWrapperLabel>
          <StyledBlogDate>{`${published_at} ${published_at_hh_mm}`}</StyledBlogDate>
          <StyledBlogLabel color={getLabelColor(label)}>{getLabelInfo(label)}</StyledBlogLabel>
        </StyledWrapperLabel>
      </StyledWrapper>

      <StyledBlogImg src={image_url} alt="" />
      <StyledBlogContent>{content.split('\\n').join('\n')}</StyledBlogContent>
      <StyledBlogBottom>FANME運営</StyledBlogBottom>
    </>
  )

  // 既読をつける
  useFanmeBackendPost<any>(`/fanme/api/creators/self/article/${id}/view`, { auth: true })

  return (
    <>
      {loaded && <FanmeLoading />}
      <FullModal
        header={renderHeader()}
        content={renderContent()}
        visible={visible}
        zIndex={10000}
        bodyPadding="0"
        background={Colors.VERY_LIGHT_GRAY}
      />
    </>
  )
}
export default BlogArticleDetail
