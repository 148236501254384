import React, { useCallback, useState } from 'react'
import Head from 'next/head'
import getConfig from 'next/config'
import { generateSnackImageUrl } from '@/libs/utils'

const { publicRuntimeConfig } = getConfig()

type Props = {
  creatorName: string | null
  title: string
  description: string
  headerImage: string
  url: string
  pageTitle?: string
  isFBPixelMeta?: boolean
  creatorId: string | null
  creatorIcon: string | null
}

const AppHead = ({
  creatorName,
  title,
  description,
  headerImage,
  url,
  pageTitle,
  isFBPixelMeta = false,
  creatorId,
  creatorIcon,
}: Props) => {
  return (
    <Head>
      {creatorId ? (
        <>
          <link rel="manifest" href={`/@${creatorId}/manifest.json`} />
          {creatorIcon && <link rel="icon" href={creatorIcon} />}
          {creatorIcon && <link rel="apple-touch-icon" href={creatorIcon} />}
        </>
      ) : (
        <>
          <link rel="icon" href="/favicon.ico" />
          <link rel="manifest" href="/manifest.webmanifest" />
          <link rel="apple-touch-icon" href="/icon.png" />
        </>
      )}
      <meta httpEquiv="content-language" content="ja" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} key="og_title" />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={headerImage} key="og_image" />
      <meta property="og:url" content={url} />
      <meta name="thumbnail" content={headerImage} />
      <meta name="publisher" content="TORIHADA, inc." />
      <meta name="creator" content="TORIHADA, inc." />
      <meta
        name="keywords"
        content={`${creatorName},FANME,ファンミー,ファンミ,ふぁんみ,ふぁんみー,link in bio,links,link,portfolio,profile,プロフィール,ポートフォリオ,クリエーター,タレント,ツール,無料,リンク,まとめ,SNS`}
      />
      <meta name="description" content={description} key="description" />
      {creatorName && <meta name="author" content={creatorName} />}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={headerImage} />
      {isFBPixelMeta && (
        <meta name={process.env.FB_PIXEL_META_NAME} content={process.env.FB_PIXEL_META_CONTENT} />
      )}
      {pageTitle && <title>{pageTitle}</title>}
    </Head>
  )
}

export default AppHead
