import { create } from 'zustand'

interface NotificationState {
  hasNewNotification: boolean
  setHasNewNotification: (hasNewNotification: boolean) => void
}

export const useHasNewNotificationStore = create<NotificationState>()(set => ({
  hasNewNotification: false,
  setHasNewNotification: (hasNewNotification: boolean) => set({ hasNewNotification }),
}))
