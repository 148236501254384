import React, { useEffect, useRef, useState } from 'react'
import Cleave from 'cleave.js/react'
import creditCardType from 'credit-card-type'
import axios from 'axios'
import { MiniApp } from '@torihada-inc/miniapps'
import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import HalfModal from '@/components/atoms/Dialog/HalfModal'
import { Colors } from '@/constants/styles/color'
import HelpIcon from '@/assets/img/icons/Help.svg'
import VisaIcon from '@/assets/img/payment/CC_Visa.svg'
import MasterIcon from '@/assets/img/payment/CC_Master.svg'
import JcbIcon from '@/assets/img/payment/CC_JCB.svg'
import AmericanExpressIcon from '@/assets/img/payment/CC_AmericanExpress.svg'
import DinersIcon from '@/assets/img/payment/CC_Diners.svg'
import { getCardTypeName } from '@/fanmeSdk/Payment/cardBrand'
import { updateCreditCard } from '@/pages/api/payment'
import HalfModalHeader from '@/components/molecules/Header/HalfModalHeader'
import { ga4UpdatePaymentInfo } from '@/libs/ga_event'
import { PaymentType } from '@/types/GAEvents'
import { GetPaymentToken } from '../paymentToken'
import AboutSecurityCode from '../AboutSecurityCode'
import FanmeSdkAlert from '../FanmeSdkAlert'
import {
  StyledCardExpirationDate,
  StyledCardInfoText,
  StyledCardName,
  StyledCardNumbers,
  StyledCardSecurityCode,
  StyledCCImages,
  StyledDialogBody,
  StyledErrMsg,
  StyledFormItem,
  StyledPurchaseButton,
  StyledRequired,
  StyledTitle,
} from './index.style'

dayjs.extend(isSameOrAfter)

/**
 * カード会社ごとの表示名・アイコン
 */
export const cardTypeIcon: any = {
  VISA: <VisaIcon />,
  MASTER: <MasterIcon />,
  AMEX: <AmericanExpressIcon />,
  DINERS: <DinersIcon />,
  JCB: <JcbIcon />,
}

type CardType = {
  niceType: string
  type: string
  gaps: Array<number>
  lengths: Array<number>
  code: { name: string; size: number }
}

interface Props {
  miniApp: MiniApp
  onClose: () => void
  backTo: () => void
  getCreditCardList: () => void
  cardInfo?: any
}

const AddCardInfo = ({ miniApp, onClose, backTo, getCreditCardList, cardInfo }: Props) => {
  const [isUpdate, setIsUpdate] = useState<boolean>(false)
  // セキュリティコードについてのダイアログ表示・非表示
  const [aboutDialogVisible, setAboutDialogVisible] = useState(false)
  // 番号に該当するカード会社の設定情報
  const [cardTypeData, setCardTypeData] = useState<CardType>()

  const [cardNumbers, setCardNumbers] = useState<string>('')
  const [cardName, setCardName] = useState<string>('')
  const [cardExpirationDate, setCardExpirationDate] = useState<string>('')
  const [cardSecurityCode, setCardSecurityCode] = useState<string>('')

  const [cardNumErrMsg, setCardNumErrMsg] = useState<string>('')
  const [cardNameErrMsg, setCardNameErrMsg] = useState<string>('')
  const [cardExpirationDateErrMsg, setCardExpirationDateErrMsg] = useState<string>('')
  const [cardSecCodeErrMsg, setCardSecCodeErrMsg] = useState<string>('')

  // ref input
  const cardInput: any = useRef(null)
  const nameInput = useRef<HTMLInputElement>(null)
  const codeInput = useRef<HTMLInputElement>(null)

  // 登録ボタンの活性・非活性
  const [buttonDisable, setButtonDisable] = useState(true)

  const [successAlertVisible, setSuccessAlertVisible] = useState(false)
  const [errAlertVisible, setErrAlertVisible] = useState(false)
  const [errAlertMsg, setErrAlertMsg] = useState<string>('')
  const errAlert = (msg?: string) => {
    if (msg) {
      setErrAlertMsg(msg)
      setErrAlertVisible(true)
    } else {
      setErrAlertMsg('')
      setErrAlertVisible(false)
    }
  }

  useEffect(() => {
    if (!isUpdate) {
      if (
        !cardNumbers ||
        !cardName ||
        !cardExpirationDate ||
        !cardSecurityCode ||
        cardNumErrMsg !== '' ||
        cardNameErrMsg !== '' ||
        cardExpirationDateErrMsg !== '' ||
        cardSecCodeErrMsg !== ''
      ) {
        setButtonDisable(true)
      } else {
        setButtonDisable(false)
      }
    } else {
      !cardExpirationDate || cardExpirationDateErrMsg !== ''
        ? setButtonDisable(true)
        : setButtonDisable(false)
    }
  }, [
    isUpdate,
    cardNumbers,
    cardName,
    cardExpirationDate,
    cardSecurityCode,
    cardNumErrMsg,
    cardNameErrMsg,
    cardExpirationDateErrMsg,
    cardSecCodeErrMsg,
  ])

  useEffect(() => {
    if (0 <= cardInfo?.cardId) setIsUpdate(true)
    if (cardInfo?.cardNumber) setCardNumbers(cardInfo.cardNumber)
    if (cardInfo?.cardHolderName) setCardName(cardInfo.cardHolderName)
    if (cardInfo?.cardExpiresAt) {
      const expiresAt = cardInfo.cardExpiresAt.match(/.{2}/g)?.reverse()?.join('/')
      if (expiresAt) setCardExpirationDate(expiresAt)
    }
  }, [cardInfo])

  const goToNextInput = (inputType: string) => {
    switch (inputType) {
      case 'nameInput':
        nameInput?.current && nameInput.current!.focus()
        break
      case 'codeInput':
        codeInput?.current && codeInput.current!.focus()
        break
      default:
        break
    }
  }

  /**
   * 入力されたカード番号の設定
   */
  const changeNumber = (val: string) => {
    const target = val.replace(/ /g, '')
    setCardNumbers(target)

    if (!target) {
      setCardNumErrMsg('必須です')
      return
    }

    const data = creditCardType(target)
    if (data.length > 0) {
      const cardType = data[0]
      setCardTypeData(cardType)

      if (target.length !== cardType.lengths[0]) {
        setCardNumErrMsg('不正な値です')
      } else {
        setCardNumErrMsg('')
        goToNextInput('nameInput')
      }
    } else {
      setCardTypeData(undefined)
      setCardNumErrMsg('不正な値です')
    }
  }

  /**
   * 入力されたカード名義人の設定
   */
  const changeCardName = (name: string) => {
    if (!name) {
      setCardNameErrMsg('必須です')
      setCardName('')
      return
    }

    //入力された文字を半角に変更
    const inputCardName = name
      .replace(/[\uFF01-\uFF5E]/g, match => {
        return String.fromCharCode(match.charCodeAt(0) - 0xfee0)
      })
      .replace(/\u3000/g, ' ')
      .toUpperCase()
    nameInput.current!.value = inputCardName

    setCardName(inputCardName)
    if (!/^[A-Z]+\s[A-Z]+$/.test(inputCardName)) {
      setCardNameErrMsg('不正な値です')
    } else {
      setCardNameErrMsg('')
    }
  }

  /**
   * 入力された有効期限の設定
   */
  const changeCardExpirationDate = (date: string) => {
    if (date) {
      setCardExpirationDate(date)
      const dateArray = date.split('/')
      dateArray.reverse() // 配列を逆に
      // 来月以降（未来）ではない場合
      if (
        !dayjs(`20${dateArray[0]}-${dateArray[1]}`)
          .endOf('month')
          .isSameOrAfter(dayjs().endOf('month'))
      ) {
        setCardExpirationDateErrMsg('不正な値です')
      } else {
        setCardExpirationDateErrMsg('')
        goToNextInput('codeInput')
      }
    } else {
      setCardExpirationDateErrMsg('必須です')
      setCardExpirationDate('')
    }
  }

  /**
   * 入力されたセキュリティコードの設定
   */
  const changeCardSecurityCode = (code: string) => {
    if (!code) {
      setCardSecCodeErrMsg('必須です')
      return
    }
    setCardSecurityCode(code)

    const codeLength = cardTypeData?.code?.size ? [cardTypeData?.code?.size] : [3, 4]
    if (!codeLength.includes(code.length) || !/^[0-9]+$/.test(code)) {
      setCardSecCodeErrMsg('不正な値です')
    } else {
      setCardSecCodeErrMsg('')
    }
  }

  /**
   * 新しいクレジットカード情報を登録する
   */
  const addCreditCard = async () => {
    try {
      // クレカユーザーに登録されているかチェック
      const isExistUser = await miniApp?.user.checkCreditCardUser()

      if (!isExistUser) {
        // クレカユーザーに登録されていない場合、ユーザー登録から
        const test = await miniApp?.user.registerCreditCardUser(cardName)
      }

      const expiresAt = cardExpirationDate.split('/').reverse().join('')
      // トークン取得
      const token = await GetPaymentToken({
        cardNo: cardNumbers,
        expire: expiresAt,
        securityCode: cardSecurityCode,
        cardUserName: cardName,
      })

      if (!token) {
        errAlert('カード情報の登録に失敗しました')
        setButtonDisable(false)
        return
      }

      const result = await miniApp?.user.registerCreditCard(token, getCardTypeName(cardNumbers))
      // AxiosErrorだったらエラーアラートを表示
      if (axios.isAxiosError(result)) {
        errAlert('カード情報の登録に失敗しました')
        setButtonDisable(false)
      } else {
        setSuccessAlertVisible(true)
      }
    } catch (e) {
      console.error(e)
      errAlert('カード情報の登録に失敗しました')
      setButtonDisable(false)
    }
  }

  const updateCardInfo = async () => {
    try {
      const expiresAt = cardExpirationDate.split('/').reverse().join('')

      const result = await updateCreditCard({
        card_sequence: cardInfo?.cardId?.toString(),
        card_holder_name: cardInfo?.cardHolderName,
        card_name: cardInfo?.cardName,
        expire: expiresAt,
      })
      // AxiosErrorだったらエラーアラートを表示
      if (axios.isAxiosError(result)) {
        errAlert('カード情報の更新に失敗しました')
        setButtonDisable(false)
      } else {
        // 成功時は初期化
        clearValue()
        setSuccessAlertVisible(true)
      }
    } catch (e) {
      console.error(e)
      errAlert('カード情報の更新に失敗しました')
      setButtonDisable(false)
    } finally {
      ga4UpdatePaymentInfo(PaymentType.CreditCard, [])
    }
  }

  const clearValue = () => {
    setCardNumbers('')
    setCardName('')
    setCardExpirationDate('')
    setCardSecurityCode('')
  }

  const renderHeader = () => (
    <HalfModalHeader
      title={!isUpdate ? 'カード情報の登録' : 'カード情報の編集'}
      onClose={onClose}
      onBack={backTo}
      background={Colors.WHITE}
      textColor={Colors.BLACK}
    />
  )

  const renderContent = () => (
    <>
      <StyledDialogBody>
        {!isUpdate && (
          <StyledCCImages>
            <VisaIcon />
            <MasterIcon />
            <JcbIcon />
            <AmericanExpressIcon />
            <DinersIcon />
          </StyledCCImages>
        )}
        <StyledFormItem>
          <StyledTitle>カード番号</StyledTitle>
          {!isUpdate && <StyledRequired>*必須</StyledRequired>}
        </StyledFormItem>
        {!isUpdate ? (
          <StyledCardNumbers isExistErrMsg={cardNumErrMsg ? true : false}>
            <Cleave
              placeholder="1234 5678 9123 4567"
              options={{ creditCard: true }}
              onChange={e => changeNumber(e.target.value)}
              inputMode="numeric"
              pattern="[0-9]*"
              value={cardNumbers}
              ref={cardInput}
              autoFocus={true}
            />
            {cardNumErrMsg && <StyledErrMsg>{cardNumErrMsg}</StyledErrMsg>}
          </StyledCardNumbers>
        ) : (
          <StyledCardInfoText>
            {cardTypeIcon[cardInfo?.cardName]}
            {cardNumbers}
          </StyledCardInfoText>
        )}

        <StyledFormItem>
          <StyledTitle>カード名義人</StyledTitle>
          {!isUpdate && <StyledRequired>*必須</StyledRequired>}
        </StyledFormItem>
        {!isUpdate ? (
          <StyledCardName isExistErrMsg={cardNameErrMsg ? true : false}>
            <input
              placeholder="TAROU YAMADA"
              type="text"
              onBlur={e => changeCardName(e.target.value)}
              ref={nameInput}
              pattern="^[A-Z]*$"
            />
            {cardNameErrMsg && <StyledErrMsg>{cardNameErrMsg}</StyledErrMsg>}
          </StyledCardName>
        ) : (
          <StyledCardInfoText>{cardName}</StyledCardInfoText>
        )}

        <StyledFormItem>
          <StyledTitle>有効期限</StyledTitle>
          <StyledRequired>*必須</StyledRequired>
        </StyledFormItem>
        <StyledCardExpirationDate isExistErrMsg={cardExpirationDateErrMsg ? true : false}>
          <Cleave
            placeholder="MM/YY"
            options={{ date: true, datePattern: ['m', 'y'] }}
            onChange={(e: any) => changeCardExpirationDate(e.target.value)}
            inputMode="numeric"
            pattern="[0-9]*"
            value={cardExpirationDate}
          />
          {cardExpirationDateErrMsg && <StyledErrMsg>{cardExpirationDateErrMsg}</StyledErrMsg>}
        </StyledCardExpirationDate>

        {!isUpdate && (
          <>
            <StyledFormItem>
              <StyledTitle>セキュリティコード</StyledTitle>
              <StyledRequired>*必須</StyledRequired>
            </StyledFormItem>
            <StyledCardSecurityCode isExistErrMsg={cardNumErrMsg ? true : false}>
              <input
                type="number"
                name="securityCode"
                onChange={e => changeCardSecurityCode(e.target.value)}
                pattern="[0-9]*"
                ref={codeInput}
              />
              <HelpIcon width={20} height={20} onClick={() => setAboutDialogVisible(true)} />
            </StyledCardSecurityCode>
            {cardSecCodeErrMsg && <StyledErrMsg>{cardSecCodeErrMsg}</StyledErrMsg>}
          </>
        )}

        <StyledPurchaseButton
          disabled={buttonDisable}
          onClick={() => {
            setButtonDisable(true)
            !isUpdate ? addCreditCard() : updateCardInfo()
          }}
        >
          {!isUpdate ? 'カード情報の登録' : 'カード情報の更新'}
        </StyledPurchaseButton>
      </StyledDialogBody>
      <AboutSecurityCode
        onClose={() => setAboutDialogVisible(false)}
        hidden={!aboutDialogVisible}
      />
    </>
  )

  return (
    <>
      <FanmeSdkAlert
        text={errAlertMsg}
        visible={errAlertVisible}
        closeBtnText="閉じる"
        exclamationIconOn={true}
        onClose={() => {
          errAlert()
          setButtonDisable(false)
        }}
      />
      <FanmeSdkAlert
        text={!isUpdate ? 'カード情報を登録しました' : 'カード情報を更新しました'}
        visible={successAlertVisible}
        checkIconOn={true}
        closeBtnText="閉じる"
        onClose={async () => {
          setSuccessAlertVisible(false)
          await getCreditCardList()
          backTo()
        }}
        actionButtonColor={Colors.BLACK}
        actionButtonTextColor={Colors.WHITE}
        actionButtonBorderColor={Colors.WHITE}
      />
      <HalfModal header={renderHeader()} content={renderContent()} top={46}></HalfModal>
    </>
  )
}

export default AddCardInfo
