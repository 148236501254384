import styled from '@emotion/styled'
import { Colors } from '@/constants/styles/color'

const StyledAppItemImg = styled.img`
  min-width: 160px;
  max-width: 100%;
  margin: 16px auto 24px;
  object-fit: cover;
`

const StyledAppItemNoneImg = styled.div`
  width: 160px;
  height: 160px;
  background: ${Colors.BK_GRAY};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px auto;
  svg {
    display: block;
    margin: 16px 0 auto;
  }
`

const StyledAppItemTitle = styled.div`
  font-size: 15px;
  font-weight: 500;
  line-height: 23px;
  color: ${Colors.PRIMARY_GRAY};
  margin-bottom: 24px;
  width: 100%;
  text-align: center;
`

const StyledAppItemDescription = styled.div`
  font-size: 13px;
  font-weight: 400;
  line-height: 21px;
  color: ${Colors.PRIMARY_GRAY};
  margin: 0 16px 24px;
  width: calc(100% - 32px);
  text-align: center;
`

const StyledPurchasedAtContainer = styled.div`
  width: 100%;
  border-radius: 8px;
  border: 8px solid ${Colors.ULTRA_LIGHT_GRAY};
  background: ${Colors.WHITE};
  padding: 16px;
`

const StyledPurchasedAtDetail = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

const StyledPurchasedAtDetailName = styled.div`
  font-size: 10px;
  font-weight: 500;
  line-height: 10px;
  letter-spacing: 0em;
  color: ${Colors.PRIMARY_GRAY};
  text-align: right;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 40%;
`

const StyledPurchasedAtDetailPrice = styled.div`
  letter-spacing: 0em;
  color: ${Colors.PRIMARY_GRAY};
  text-align: right;
  white-space: nowrap;
  text-overflow: ellipsis;
  .span-price {
    font-size: 13px;
    font-weight: 500;
    line-height: 11px;
    color: ${Colors.PRIMARY_GRAY};
    font-family: 'Montserrat';
  }
`

const StyledPurchasedAtDetailDate = styled.div`
  font-size: 11px;
  font-weight: 500;
  line-height: 11px;
  letter-spacing: 0em;
  color: ${Colors.PRIMARY_GRAY};
  text-align: right;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export {
  StyledAppItemImg,
  StyledAppItemNoneImg,
  StyledAppItemTitle,
  StyledAppItemDescription,
  StyledPurchasedAtContainer,
  StyledPurchasedAtDetail,
  StyledPurchasedAtDetailName,
  StyledPurchasedAtDetailPrice,
  StyledPurchasedAtDetailDate,
}
