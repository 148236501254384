import React, { useEffect, useState } from 'react'
import getConfig from 'next/config'
import { MiniApp } from '@torihada-inc/miniapps'
import { SellerAccountTransferResponse } from '@torihada-inc/miniapps/dist/repositories/user/SellerAccountTransferRepository'
import numral from 'numeral'
import { Colors } from '@/constants/styles/color'
import FullModal from '@/components/atoms/Dialog/FullModal'
import FullModalHeader from '@/components/molecules/Header/FullModalHeader'
import { useAuth } from '@/contexts/AuthContext'
import Button from '@/components/atoms/Button'
import { getMiniAppInfo } from '@/pages/api/miniapp'
import { FmePaddingContainer } from '@/fanmeUi/layouts/container'
import MoneyManagementApplication from '@/components/molecules/MyMenu/MoneyManagementApplication'
import MoneyManagementAuthentication from '../MoneyManagementAuthentication'
import {
  MoneyManageDescription,
  SalesAmount,
  SalesAmountBtnContainer,
  SalesAmountColumn,
  SalesAmountColumns,
  SalesAmountContainer,
  SalesAmountName,
  SalesBalanceAmount,
  SalesContainerAmount,
  SalesReceiptBorder,
  SalesReceiptColumn,
  SalesReceiptContainer,
  TransferFeeAmount,
} from './index.style'

interface Props {
  accountId: string
  visible: boolean
  onClose: () => void
  month: number | undefined | undefined
  errorState: () => void
  infoState: () => void
  updateState: () => void
}

const { publicRuntimeConfig } = getConfig()

const MoneyManagementReceipt = ({
  accountId,
  visible,
  onClose,
  month,
  errorState,
  infoState,
  updateState,
}: Props) => {
  const { token } = useAuth()
  const [miniApp, setMiniApp] = useState<MiniApp | undefined>()
  const [transferData, setTransferData] = useState<SellerAccountTransferResponse>()
  const [authenticate, setAuthenticate] = useState(false)
  const [application, setApplication] = useState(false)

  const initMiniApp = async () => {
    const res: any = await getMiniAppInfo(accountId)
    const miniAppData = new MiniApp(publicRuntimeConfig.PAYMENT_URL, res.user_uid, token as string)
    setMiniApp(miniAppData)
  }

  useEffect(() => {
    miniApp?.user.getSellerTransfer().then((res: SellerAccountTransferResponse) => {
      setTransferData(res)
    })
  }, [miniApp])

  useEffect(() => {
    if (token && visible) {
      initMiniApp()
    }
  }, [accountId, token, visible])

  const renderHeader = () => <FullModalHeader title="売上申請" onBack={onClose} />

  const renderContent = () => (
    <FmePaddingContainer left={24} right={24} top={24} bottom={24}>
      <MoneyManageDescription>売上金残高</MoneyManageDescription>
      <SalesBalanceAmount>￥{numral(transferData?.balance).format('0,0')}</SalesBalanceAmount>

      <MoneyManageDescription>受取方法</MoneyManageDescription>
      <SalesReceiptContainer>
        <SalesReceiptBorder>銀行振込</SalesReceiptBorder>
        <SalesReceiptColumn>
          {month}月
          <SalesAmountColumn>
            ￥{numral(transferData?.transfer_amount).format('0,0')}
          </SalesAmountColumn>
        </SalesReceiptColumn>

        <SalesAmountContainer>
          <SalesAmountColumns>
            <SalesAmountName>売上金</SalesAmountName>
            <SalesAmountName>振込手数料</SalesAmountName>
          </SalesAmountColumns>

          <SalesAmount>
            <SalesContainerAmount>
              ￥{numral(transferData?.total_amount).format('0,0')}
            </SalesContainerAmount>
            <TransferFeeAmount>
              -￥{numral(transferData?.transfer_fee).format('0,0')}
            </TransferFeeAmount>
          </SalesAmount>
        </SalesAmountContainer>

        <SalesAmountBtnContainer>
          <Button
            text={'振込申請'}
            width={'100%'}
            height={'48px'}
            fontFamily={'Noto Sans JP'}
            fontWeight={'700'}
            fontSize={'12px'}
            lineHeight={'12px'}
            borderRadius={'24px'}
            bgColor={Colors.PRIMARY_GRAY}
            textColor={Colors.WHITE}
            onClick={() => setApplication(true)}
          />
        </SalesAmountBtnContainer>
      </SalesReceiptContainer>
    </FmePaddingContainer>
  )

  return (
    <>
      <FullModal
        header={renderHeader()}
        content={renderContent()}
        visible={visible}
        zIndex={10000}
        bodyPadding="0"
      />
      <MoneyManagementApplication
        accountId={accountId}
        visible={application}
        onClose={() => setApplication(false)}
        authenticate={() => setAuthenticate(true)}
        backManagement={onClose}
        errorState={errorState}
        infoState={infoState}
        updateState={updateState}
      />
      <MoneyManagementAuthentication
        accountId={accountId}
        visible={authenticate}
        onClose={() => setAuthenticate(false)}
      />
    </>
  )
}

export default MoneyManagementReceipt
