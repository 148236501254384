import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Tokyo')

/**
 * UTC時間を
 * 日本時間（JST）に変換する
 * @param date 日時（YYYY-MM-DD hh:mm:ss）
 * @returns 日本時間（JST）
 */
export const convertJST = (date: string | Date) => {
  const UTCDate = dayjs.utc(date).format()
  return dayjs(UTCDate).tz().toDate()
}

/**
 * 渡された日時が現在からどのくらい前かを返す
 * @param date 日時
 * @returns どのくらい前か
 */
export const getDateDiff = (date: Date) => {
  const diffSecond = dayjs().diff(dayjs(date), 's')
  if (diffSecond < 60) return `${diffSecond}秒前`
  const diffMinutes = dayjs().diff(dayjs(date), 'm')
  if (diffMinutes < 60) return `${diffMinutes}分前`
  const diffHours = dayjs().diff(dayjs(date), 'h')
  if (diffHours < 24) return `${diffHours}時間前`
  const diffDays = dayjs().diff(dayjs(date), 'd')
  if (diffDays < 31) return `${diffDays}日前`
  const diffWeek = dayjs().diff(dayjs(date), 'w')
  if (diffWeek < 4) return `${diffWeek}週間前`
  const diffMonth = dayjs().diff(dayjs(date), 'M')
  if (diffMonth < 12) return `${diffMonth}ヶ月前`
  const diffYears = dayjs().diff(dayjs(date), 'y')
  return `${diffYears}年前`
}

/**
 * 渡された日時が現在からどのくらい前かを返す
 * １ヶ月以上前の場合は日付をフォーマットして返す
 * @param date 日時
 * @returns どのくらい前か or 日付
 */
export const getTimeDifferenceText = (targetUtc: dayjs.Dayjs): string => {
  const nowUtc = dayjs().utc() // 現在のUTC日時

  const diffSecond = nowUtc.diff(targetUtc, 'second') // 秒単位で差分を取得
  if (diffSecond < 60) return `${diffSecond}秒前`

  const diffMinutes = nowUtc.diff(targetUtc, 'minute') // 分単位で差分を取得
  if (diffMinutes < 60) return `${diffMinutes}分前`

  const diffHours = nowUtc.diff(targetUtc, 'hour') // 時間単位で差分を取得
  if (diffHours < 24) return `${diffHours}時間前`

  const diffDays = nowUtc.diff(targetUtc, 'day') // 日単位で差分を取得
  if (diffDays < 30) return `${diffDays}日前`

  // 1ヶ月以上前の場合は日付をそのまま表示
  return targetUtc.tz('Asia/Tokyo').format('YYYY/MM/DD')
}

/**
 * 渡された日時が今週かどうか返す
 * 「今週」を月曜始まりとする
 * @param date 日時
 * @returns true: 今週である, false: 今週ではない
 */
export const isThisWeek = (date: Date) => {
  const day = dayjs().day()
  const weekStartDate =
    day === 0
      ? dayjs().startOf('week').subtract(1, 'w').toDate()
      : dayjs().startOf('week').add(1, 'd').toDate()
  return weekStartDate.getTime() <= date.getTime()
}
