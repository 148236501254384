import React, { useState } from 'react'
import News from '@/assets/img/icons/News.svg'
import {
  StyledBadgeInnerContainer,
  StyledBadgeOuterContainer,
} from '@/components/molecules/BlogArticleBadge/index.style'
import BlogArticles from '../../BlogArticles'
import BlogArticleBadge from '../../BlogArticleBadge'
import { StyledMyMenuItem, StyledMyMenuItemName } from '../index.style'

const BlogPage = () => {
  const [visibleBlogPage, setVisibleBlogPage] = useState(false)

  return (
    <>
      <StyledMyMenuItem onClick={() => setVisibleBlogPage(true)}>
        <StyledBadgeOuterContainer>
          <StyledBadgeInnerContainer right={-10} top={-5}>
            <BlogArticleBadge />
          </StyledBadgeInnerContainer>
          <News width={20} height={20} />
        </StyledBadgeOuterContainer>
        <StyledMyMenuItemName>お知らせ</StyledMyMenuItemName>
      </StyledMyMenuItem>

      {visibleBlogPage && <BlogArticles onClose={() => setVisibleBlogPage(false)} />}
    </>
  )
}

export default BlogPage
