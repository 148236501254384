import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import CloseM from '@/assets/img/icons/Close_M.svg'
import Login from '@/assets/img/icons/Login.svg'
import Official from '@/assets/img/icons/Official.svg'
import CreatorPage from '@/components/molecules/MyMenu/CreatorPage'
import AccountSettings from '@/components/molecules/MyMenu/AccountSettings'
import MoneyManagement from '@/components/molecules/MyMenu/MoneyManagement'
import Notification from '@/components/molecules/MyMenu/Notification'
import Help from '@/components/molecules/MyMenu/Help'
import FanmeApps from '@/components/molecules/MyMenu/FanmeApps'
import FullModal from '@/components/atoms/Dialog/FullModal'
import { event } from '@/pages/api/gtag'
import { DISPLAY_INFO_BLOG } from '@/constants'
import { Colors } from '@/constants/styles/color'
import Dashboard from '@/components/molecules/MyMenu/Dashboard'
import { useCurrentUser } from '@/contexts/AuthContext'
import HeadeLine from '@/components/atoms/HeadLine'
import Contact from '@/components/molecules/MyMenu/Contacts'
import BirthdayInput from '@/components/molecules/Profile/BirthdayInput'
import { useUser } from '@/libs/fanme_backend'
import InfoDialog, { InfoState } from '../Alert/info'
import MylibraryMenu from './Mylibrary'
import TwitterPage from './Info'
import BlogPage from './BlogPage'
import {
  StyledMyMenuAccordionButton,
  StyledMyMenuAccountIdentity,
  StyledMyMenuAvatar,
  StyledMyMenuBorderLine,
  StyledMyMenuCheckmark,
  StyledMyMenuClose,
  StyledMyMenuContainer,
  StyledMyMenuCreatorName,
  StyledMyMenuCreatorSettings,
  StyledMyMenuCreatorStatus,
  StyledMyMenuHeader,
  StyledMyMenuHeaderGrid,
  StyledMyMenuNonAvatar,
  StyledMyMenuWrapper,
} from './index.style'

interface Props {
  officialFlag: boolean | undefined
  visible: boolean
  setVisible: (visible: boolean) => void
  icon: string
  name: string
  accountIdentity: string
}

const MyMenu = ({ officialFlag, visible, setVisible, icon, name, accountIdentity }: Props) => {
  const [avatar, setAvatar] = useState<string | undefined>(icon)
  const [infoState, setInfoState] = useState({} as InfoState)
  const [creatorMenuOpen, setCreatorMenuOpen] = useState(true)
  const { query, isReady } = useRouter()
  const router = useRouter()
  const { data: profile } = useUser()

  useEffect(() => {
    if (isReady && query.update) {
      if (query.result === 'success') {
        setInfoState(successChangeInfo(query.update as string))
        setVisible(true)
      }
    }
  }, [isReady, query])

  const successChangeInfo = (updateType?: string) => {
    let infoMessage = ''
    if (updateType === 'auth') {
      infoMessage = 'パスワードを変更しました。'
    } else {
      infoMessage = 'メールアドレスを変更しました'
    }
    return {
      message: infoMessage,
      close: () => {
        setInfoState(new Object() as InfoState)
        customParamRemove(query.result as string)
      },
      visible: true,
    }
  }

  useEffect(() => {
    setAvatar(icon)
  }, [icon])

  const goCreatorPage = () => {
    const creatorPagePath = `/@${accountIdentity}`

    event({
      action: 'click',
      category: 'other',
      value: 'creator_page',
    })

    if (location.pathname !== creatorPagePath) {
      router.push(`/@${accountIdentity}`)
      return
    }

    setVisible(false)
  }

  const customParamRemove = (queryStr: string) => {
    const params = new URLSearchParams(queryStr)
    params.delete('result')
    router.replace(`/${accountIdentity}`, '', { scroll: false })
  }

  const closeMyMenu = () => {
    setVisible(false)
    setTimeout(() => {
      const elemSnapParent = document.querySelector('#snapParent')
      elemSnapParent?.scrollTo(0, 0)
    }, 100)
  }

  const toggleAccordion = () => {
    setCreatorMenuOpen(!creatorMenuOpen)
  }

  const renderHeader = () => (
    <StyledMyMenuHeader>
      <StyledMyMenuHeaderGrid onClick={goCreatorPage}>
        {!avatar ? (
          <StyledMyMenuNonAvatar>
            <Login style={{ width: '20px', height: '20px' }} />
          </StyledMyMenuNonAvatar>
        ) : (
          <StyledMyMenuAvatar src={avatar} alt="" onError={() => setAvatar(undefined)} />
        )}

        <StyledMyMenuCreatorStatus>
          <StyledMyMenuCreatorName>
            <p>{name}</p>
            {officialFlag && (
              <Official style={{ marginLeft: '4px', verticalAlign: 'text-bottom' }} />
            )}
          </StyledMyMenuCreatorName>
          <StyledMyMenuAccountIdentity>@{accountIdentity}</StyledMyMenuAccountIdentity>
        </StyledMyMenuCreatorStatus>
      </StyledMyMenuHeaderGrid>
      <StyledMyMenuClose onClick={() => closeMyMenu()}>
        <CloseM width={14} height={14} />
      </StyledMyMenuClose>
    </StyledMyMenuHeader>
  )

  const renderContent = () => (
    <>
      {profile?.birthday === '1900-01-01' && (
        <BirthdayInput marginTop={'12px'} marginBottom={'-12px'} maxWidth={'338px'} />
      )}
      <StyledMyMenuBorderLine isOpen={creatorMenuOpen}>
        <StyledMyMenuCreatorSettings onClick={toggleAccordion}>
          <HeadeLine text="クリエイター設定" />
          <StyledMyMenuAccordionButton>
            <StyledMyMenuCheckmark isOpen={creatorMenuOpen} />
          </StyledMyMenuAccordionButton>
        </StyledMyMenuCreatorSettings>
        {creatorMenuOpen && (
          <StyledMyMenuContainer>
            <FanmeApps />
            <CreatorPage />
            <StyledMyMenuWrapper>
              <Dashboard />
              <MoneyManagement accountIdentity={accountIdentity} />
            </StyledMyMenuWrapper>
          </StyledMyMenuContainer>
        )}
      </StyledMyMenuBorderLine>
      <StyledMyMenuContainer>
        <MylibraryMenu />
        <AccountSettings accountIdentity={accountIdentity} />
        {DISPLAY_INFO_BLOG ? <BlogPage /> : <TwitterPage />}
        <Notification accountIdentity={accountIdentity} />
        <Contact />
        <Help />
      </StyledMyMenuContainer>
      <InfoDialog visible={infoState.visible} message={infoState.message} close={infoState.close} />
    </>
  )

  return (
    <FullModal
      header={renderHeader()}
      content={renderContent()}
      visible={visible}
      bodyPadding="0"
      background={Colors.ULTRA_LIGHT_GRAY}
    />
  )
}

export default MyMenu
