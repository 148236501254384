import React, { useCallback } from 'react'
import Login from '@/assets/img/icons/Login.svg'
import EditProfilePopup from '@/components/molecules/MyMenu/EditProfilePopup'
import { useEditProfiletStore } from '@/utils/hooks/useProfileModal'
import ArrowRight from '@/assets/img/icons/Arrow_Right.svg'
import {
  StyledMenuItemContainer,
  StyledMenuItemText,
  StyledMenuItemTitle,
} from '../AccountSettings/index.style'

const Profile = () => {
  const { isOpen, onOpen, onClose: onCloseEditProfile } = useEditProfiletStore()

  const onClose = useCallback(() => {
    onCloseEditProfile()
  }, [])

  return (
    <>
      <StyledMenuItemContainer onClick={onOpen}>
        <StyledMenuItemTitle>
          <Login width={16} height={16} />
          <StyledMenuItemText>プロフィール編集</StyledMenuItemText>
        </StyledMenuItemTitle>
        <ArrowRight width={16} height={16} />
      </StyledMenuItemContainer>

      {isOpen && <EditProfilePopup visible={isOpen} onClose={onClose} />}
    </>
  )
}

export default Profile
