import React from 'react'
import Link from 'next/link'
import { DEFAULT_NOTIFICATION_TITLE } from '@/constants'
import { Notification } from '../NotificationList/index'
import {
  StyledNoticeIcon,
  StyledDetail,
  StyledTitle,
  StyledDiff,
  StyledDescription,
  StyledListItem,
  StyledBadgeInnerContainer,
  StyledBadge,
  StyledBadgeOuterLinkContainer,
  StyledBadgeOuterContainer,
} from './index.style'
import { useNotificationIcon } from './useNotificationIcon'

interface Props {
  item: Notification
}

const NotificationListItem = ({ item }: Props) => {
  const notificationIcon = useNotificationIcon(item)

  const renderContent = (
    <>
      <StyledBadgeInnerContainer>{item.isNew && <StyledBadge />}</StyledBadgeInnerContainer>
      <StyledNoticeIcon>{notificationIcon}</StyledNoticeIcon>
      <StyledDetail>
        <StyledTitle>
          {item.title ?? DEFAULT_NOTIFICATION_TITLE}
          <StyledDiff>{item.diff}</StyledDiff>
        </StyledTitle>
        <StyledDescription>{item.content}</StyledDescription>
      </StyledDetail>
    </>
  )

  return item.navigationUrl ? (
    <StyledBadgeOuterLinkContainer
      href={item.navigationUrl}
      rel="noopener noreferrer"
      target="_blank"
    >
      <StyledListItem>{renderContent}</StyledListItem>
    </StyledBadgeOuterLinkContainer>
  ) : (
    <StyledBadgeOuterContainer>
      <StyledListItem>{renderContent}</StyledListItem>
    </StyledBadgeOuterContainer>
  )
}

export default NotificationListItem
