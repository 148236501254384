import React, { useEffect, useState } from 'react'
import getConfig from 'next/config'
import { MiniApp } from '@torihada-inc/miniapps'
import AddCardInfo from '@/fanmeSdk/Payment/AddCardInfo'
import ChangePurchase from '@/fanmeSdk/Payment/ChangePurchase'
import { LoadPaymentToken } from '@/fanmeSdk/Payment/paymentToken'
import { useAuth } from '@/contexts/AuthContext'
import { getMiniAppInfo } from '@/pages/api/miniapp'
import { PURCHASE_METHOD } from '@/types/Purchase'
import { postBackend, useFanmeBackendGet } from '@/libs/fanme_backend'

interface Props {
  accountId: string
  visible: boolean
  chooseCard?: boolean
  onClose: () => void
}

const PaymentMethod = ({ accountId, visible, onClose, chooseCard }: Props) => {
  const [miniApp, setMiniApp] = useState<MiniApp | undefined>()
  const [cardInfo, setCardInfo] = useState<any[]>([])
  const [activeCardInfo, setActiveCardInfo] = useState<any>() // 使用するカード
  const [updateCardInfo, setUpdateCardInfo] = useState<any>() // 更新するカード
  const [pageType, setPageType] = useState<'purchase' | 'addCardInfo' | 'changePurchase'>(
    'purchase',
  )
  const [purchaseMethod, setPurchaseMethod] = useState('')

  const { token } = useAuth()
  const { publicRuntimeConfig } = getConfig()

  LoadPaymentToken()
  const initMiniApp = async () => {
    const res: any = await getMiniAppInfo(accountId)
    const miniAppData = new MiniApp(publicRuntimeConfig.PAYMENT_URL, res.user_uid, token as string)
    setMiniApp(miniAppData)
  }

  useEffect(() => {
    if (token) {
      initMiniApp()
    }
  }, [accountId, token])

  const { data: historyData } = useFanmeBackendGet<any>('/payment/internal/payment/method/history')

  useEffect(() => {
    if (historyData) getCreditCardList()
  }, [miniApp, historyData])

  const getCreditCardList = async () => {
    if (!miniApp) return
    try {
      const creditCards = await miniApp.user.listCreditCard()

      if (creditCards.length > 0) {
        setCardInfo(creditCards)
      }

      if (historyData?.history) {
        const lastData = historyData.history[0]
        if (creditCards && lastData.type === PURCHASE_METHOD.CARD) {
          setPurchaseMethod(PURCHASE_METHOD.CARD)
          const lastUsedCard = creditCards.find(card => card.cardId?.toString() === lastData.id)
          if (lastUsedCard) setActiveCardInfo(lastUsedCard)
        }
        if (lastData.type === PURCHASE_METHOD.CVS) {
          setPurchaseMethod(PURCHASE_METHOD.CVS)
        }
      }

      setPageType('purchase')
    } catch (e) {
      console.warn(e)
    }
  }

  const onCloseAction = () => {
    setPageType('purchase')
    onClose()
  }

  const deleteCreditCard = async (cardSequence: number) => {
    try {
      const newCardInfo = cardInfo.filter(card => card.cardId !== cardSequence)
      setCardInfo(newCardInfo)
      setActiveCardInfo(newCardInfo ? newCardInfo[0] : null)

      await miniApp?.user.deleteCreditCard(cardSequence)
    } catch (e) {
      console.warn(e)
    }
  }

  const toAddCvsInfo = () => changePurchaseMethod(PURCHASE_METHOD.CVS)

  const changePurchaseMethod = async (method: string, cardId?: number) => {
    try {
      let id = ''
      setPurchaseMethod(method)
      if (method === PURCHASE_METHOD.CARD) {
        const activeCard = cardInfo.find(card => card.cardId === cardId)
        id = activeCard.cardId
        setActiveCardInfo(activeCard)
      }

      await postBackend(
        `/payment/internal/payment/method/history/touch`,
        {
          type: method,
          id: `${id}`,
        },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )
    } catch (e) {
      console.error(e)
    }
  }

  const backTo = () => {
    setPageType('purchase')
    setUpdateCardInfo(undefined)
  }

  if (!visible || !miniApp) return <></>

  return (
    <>
      {pageType === 'purchase' && (
        <ChangePurchase
          cardInfo={cardInfo}
          activeCardInfo={activeCardInfo}
          toAddCardInfo={() => setPageType('addCardInfo')}
          toAddCvsInfo={toAddCvsInfo}
          changePurchaseMethod={changePurchaseMethod}
          deleteCreditCard={deleteCreditCard}
          onClose={onCloseAction}
          backTo={onCloseAction}
          purchaseMethod={purchaseMethod}
          supportPurchaseMethods={[PURCHASE_METHOD.CARD]}
          setUpdateCardInfo={setUpdateCardInfo}
          chooseCard={chooseCard}
        />
      )}
      {pageType === 'addCardInfo' && (
        <AddCardInfo
          miniApp={miniApp}
          onClose={onCloseAction}
          backTo={backTo}
          getCreditCardList={getCreditCardList}
          cardInfo={updateCardInfo}
        />
      )}
    </>
  )
}

export default PaymentMethod
