import { useEffect } from 'react'
import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()

declare const Multipayment: any

/** トークン取得 */
export const GetPaymentToken = async ({
  cardNo,
  expire,
  securityCode,
  cardUserName,
  tokenNumber,
}: RequestCardData) => {
  const reqData: CardObject = {
    cardno: cardNo,
    expire: expire,
  }
  if (securityCode) reqData.securitycode = securityCode
  if (cardUserName) reqData.holdername = cardUserName
  if (tokenNumber) reqData.tokennumber = tokenNumber

  // NOTE: FMB-1007 ローカルでクレジットカード登録が失敗するケースがあり回避するために再初期化
  if (process.env.NODE_ENV === 'development') {
    Multipayment.init(publicRuntimeConfig.PAYMENT_TOKEN_API_KEY)
  }
  const token = await new Promise<string>(resolve => {
    Multipayment.getToken(reqData, async (result: TokenResult) => {
      resolve(await (window as any).callbackPaymentToken(result))
    })
  })

  return token
}

/** トークン取得処理準備 */
export const LoadPaymentToken = () => {
  useEffect(() => {
    const apiKey = publicRuntimeConfig.PAYMENT_TOKEN_API_KEY

    const onLoadFunction = () => {
      Multipayment.init(apiKey)
    }

    /** トークン取得後のcallback処理 */
    ;(window as any).callbackPaymentToken = (result: TokenResult) => {
      if (result.resultCode !== '000') {
        console.warn(`トークン取得でエラーが発生しました。resultCode: ${result.resultCode}`)
        return ''
      }
      const token = result.tokenObject.token
      return token
    }

    // GMOトークン取得のためのJavascript
    const paymentTokenUrl = `${publicRuntimeConfig.PAYMENT_TOKEN_URL}`
    const head = document.getElementsByTagName('head')[0] as HTMLElement
    const scriptElement = document.createElement('script')
    scriptElement.src = paymentTokenUrl

    scriptElement.addEventListener('load', onLoadFunction)
    head.appendChild(scriptElement)
    return () => {
      scriptElement.removeEventListener('load', onLoadFunction)
    }
  }, [])
}

/** トークン取得結果 */
type TokenResult = {
  resultCode: string
  tokenObject: TokenObject
}

/** トークン情報 */
type TokenObject = {
  token: string
  toBeExpiredAt: string
  maskedCardNo: string
  isSecurityCodeSet: boolean
}

/** トークン取得に必要なカード情報 */
type CardObject = {
  cardno: string
  expire: string
  securitycode?: string
  holdername?: string
  tokennumber?: string
}

/** トークンを取得するカード情報 */
export type RequestCardData = {
  cardNo: string
  expire: string
  securityCode?: string
  cardUserName?: string
  tokenNumber?: string
}
