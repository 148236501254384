export const snakeToCamel = (str: string) =>
  str
    .toLowerCase()
    .replace(/([-_][a-z])/g, group => group.toUpperCase().replace('-', '').replace('_', ''))

export const snakeToCamelObject = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map(item => snakeToCamelObject(item))
  } else if (obj !== null && obj.constructor === Object) {
    const newObj: any = {}
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const newKey = snakeToCamel(key)
        newObj[newKey] = snakeToCamelObject(obj[key])
      }
    }
    return newObj
  }
  return obj
}

type SnakeToCamelObject = <T extends object>(arr: T[]) => T[]
export const snakeToCamelObjectInArray: SnakeToCamelObject = arr =>
  arr.map(obj => snakeToCamelObject(obj))
