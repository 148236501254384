import React from 'react'
import styled from '@emotion/styled'
import CreditCardIcon from '@/assets/img/payment/CreditCard.svg'
import { Colors } from '@/constants/styles/color'

const StyledContainer = styled.div<{ hidden: boolean }>`
  position: fixed;
  max-width: 480px;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 10002;
  padding: 0;
`

const StyledDialogBG = styled.div<{ hidden: boolean }>`
  display: ${props => (props.hidden ? 'none' : '')};
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
`

const StyledDialog = styled.div<{ hidden: boolean }>`
  display: ${props => (props.hidden ? 'none' : 'flex')};
  position: absolute;
  top: calc(50% - 64px);
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  background: ${Colors.WHITE};
  border-radius: 16px;
  flex-direction: column;
  width: calc(100% - 80px);
  z-index: 10003;
  padding: 0;
`

const StyledText = styled.div`
  height: 104px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Colors.BLACK};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  padding: 0 24px;
`

const StyledSecurityCord = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  svg {
    display: flex;
    margin: 0 auto;
  }
  .description {
    padding: 16px 32px;
    font-weight: 400;
    font-size: 11px;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
  }
`

const StyledCloseButtonContainer = styled.div`
  position: absolute;
  bottom: -65px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10003;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 88px;
  padding: 12px 16px;
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  color: ${Colors.BLACK};
  background: ${Colors.WHITE};
  border-radius: 16px;
  filter: drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.08));
`

interface Props {
  onClose: () => void
  hidden: boolean
}

const AboutSecurityCode = ({ hidden, onClose }: Props) => {
  return (
    <StyledContainer hidden={hidden}>
      <StyledDialogBG onClick={onClose} hidden={hidden} />
      <StyledDialog hidden={hidden}>
        <StyledText>セキュリティコードとは？</StyledText>
        <StyledSecurityCord>
          <CreditCardIcon margin="0 auto" viewBox="0 0 226 64" />
          <div className="description">
            カードの全面もくしは背面に記載されている、
            <br />
            3桁または4桁の番号をご確認ください
          </div>
        </StyledSecurityCord>
        <StyledCloseButtonContainer onClick={onClose}>閉じる</StyledCloseButtonContainer>
      </StyledDialog>
    </StyledContainer>
  )
}

export default AboutSecurityCode
