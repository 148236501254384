import React from 'react'
import styled from '@emotion/styled'
import { Colors } from '@/constants/styles/color'
import Exclamation from '@/assets/img/icons/Exclamation.svg'
import Check from '@/assets/img/icons/Check_BK.svg'

const StyledContainer = styled.div`
  position: absolute;
  max-width: 480px;
  width: 100vw;
  height: 100vh;
  height: 100svh;
  left: 0;
  top: 0;
  z-index: 10002;
  padding: 0;
  display: flex;
`

const StyledAlertBG = styled.div`
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
`

const StyledAlert = styled.div`
  display: flex;
  position: relative;
  background: ${Colors.WHITE};
  border-radius: 16px;
  flex-direction: column;
  margin: auto;
  width: calc(100% - 80px);
  z-index: 10003;
  padding: 0;
`

const StyledCheckIcon = styled.div`
  width: 64px;
  height: 64px;
  padding: 4px;
  background: ${Colors.WHITE};
  border-radius: 50%;
  margin: -32px auto 0 auto;
  .icon-base {
    display: flex;
    width: 56px;
    height: 56px;
    background: ${Colors.FANME_YELLOW};
    border-radius: 50%;
    img {
      margin: auto;
      width: 32px;
      height: 32px;
    }
  }
`

const StyledExclamationIcon = styled.div`
  width: 64px;
  height: 64px;
  padding: 4px;
  background: ${Colors.WHITE};
  border-radius: 50%;
  margin: -32px auto 0 auto;
  .icon-base {
    display: flex;
    width: 56px;
    height: 56px;
    background: ${Colors.RED};
    border-radius: 50%;
    img {
      margin: auto;
      width: 32px;
      height: 32px;
    }
  }
`

const StyledMain = styled.div`
  margin: 50px 24px;
`

const StyledText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Colors.BLACK};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  white-space: pre-wrap;
`

const StyledSubText = styled.div`
  color: ${Colors.BLACK};
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
`

const StyledActionButton = styled.button<{
  actionButtonColor?: string
  actionButtonTextColor?: string
  actionButtonBorderColor?: string
}>`
  height: 48px;
  background: ${props => (props.actionButtonColor ? props.actionButtonColor : Colors.WHITE)};
  border: ${props =>
    props.actionButtonBorderColor
      ? `1px solid ${props.actionButtonBorderColor}`
      : `1px solid ${Colors.RED}`};
  color: ${props => (props.actionButtonTextColor ? props.actionButtonTextColor : Colors.RED)};
  border-radius: 24px;
  margin: 0 24px 24px 24px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
`

const StyledCloseButtonContainer = styled.div`
  position: absolute;
  bottom: -65px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10003;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 88px;
  padding: 12px 16px;
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  color: ${Colors.BLACK};
  background: ${Colors.WHITE};
  border-radius: 16px;
  filter: drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.08));
`

interface Props {
  text: string
  closeBtnText?: string
  visible: boolean
  checkIconOn?: boolean
  exclamationIconOn?: boolean
  action?: string
  onAction?: () => void
  onClose?: () => void
  actionButtonColor?: string
  actionButtonTextColor?: string
  actionButtonBorderColor?: string
  subTitle?: string
}

const FanmeSdkAlert = ({
  text,
  closeBtnText,
  visible,
  checkIconOn,
  exclamationIconOn,
  action,
  onClose,
  onAction,
  actionButtonColor,
  actionButtonTextColor,
  actionButtonBorderColor,
  subTitle,
}: Props) => {
  if (!visible) return <></>
  return (
    <>
      <StyledContainer>
        <StyledAlertBG onClick={onClose} />
        <StyledAlert>
          {checkIconOn && (
            <StyledCheckIcon>
              <div className="icon-base">
                <Check />
              </div>
            </StyledCheckIcon>
          )}
          {exclamationIconOn && (
            <StyledExclamationIcon>
              <div className="icon-base">
                <Exclamation
                  width={32}
                  height={32}
                  fill={Colors.WHITE}
                  style={{
                    margin: 'auto',
                  }}
                />
              </div>
            </StyledExclamationIcon>
          )}

          <StyledMain>
            <StyledText>{text}</StyledText>
            {subTitle && <StyledSubText>{subTitle}</StyledSubText>}
          </StyledMain>

          {action && (
            <StyledActionButton
              actionButtonColor={actionButtonColor}
              actionButtonTextColor={actionButtonTextColor}
              actionButtonBorderColor={actionButtonBorderColor}
              onClick={onAction}
            >
              {action}
            </StyledActionButton>
          )}
          {closeBtnText && onClose && (
            <StyledCloseButtonContainer onClick={onClose}>
              {closeBtnText}
            </StyledCloseButtonContainer>
          )}
        </StyledAlert>
      </StyledContainer>
    </>
  )
}

export default FanmeSdkAlert
