import styled from '@emotion/styled'
import { Colors } from '@/constants/styles/color'

const MoneyManageDescription = styled.div`
  height: 24px;
  padding-left: 12px;
  border-left: 1px solid ${Colors.PRIMARY_GRAY};
  color: ${Colors.PRIMARY_GRAY};
  font-size: 13px;
  font-weight: 500;
  line-height: 13px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`

const SalesBalanceAmount = styled.div`
  font-size: 30px;
  font-weight: 600;
  line-height: 30px;
  color: ${Colors.PRIMARY_GRAY};
  margin: 24px 0;
`

const SalesReceiptContainer = styled.div`
  background: ${Colors.WHITE};
  border-radius: 8px;
  width: 100%;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);
`

const SalesReceiptColumn = styled.div`
  color: ${Colors.PRIMARY_GRAY};
  height: 64px;
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
  line-height: 14px;
  margin: 0 16px;
  border-bottom: 1px solid ${Colors.VERY_LIGHT_GRAY};
`

const SalesReceiptBorder = styled.div`
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: ${Colors.PRIMARY_GRAY};
  padding: 0 16px;
  color: ${Colors.WHITE};
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 500;
  line-height: 13px;
  height: 40px;
`

const SalesAmountColumn = styled.div`
  margin: auto 0px auto auto;
  font-size: 22px;
  font-weight: 600;
  line-height: 22px;
  color: ${Colors.PRIMARY_GRAY};
  display: flex;
`

const SalesAmountName = styled.div`
  font-size: 11px;
  font-weight: 400;
  line-height: 11px;
  color: ${Colors.PRIMARY_GRAY};
`

const SalesContainerAmount = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  color: ${Colors.PRIMARY_GRAY};
`

const TransferFeeAmount = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  color: ${Colors.RED};
`

const SalesAmountColumns = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  row-gap: 8px;
`

const SalesAmountContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 16px;
  margin: 12px 16px 24px 16px;
`

const SalesAmount = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;
  row-gap: 8px;
`

const SalesAmountBtnContainer = styled.div`
  padding: 0 16px 16px;
`

export {
  MoneyManageDescription,
  SalesBalanceAmount,
  SalesReceiptContainer,
  SalesReceiptColumn,
  SalesReceiptBorder,
  SalesAmountColumn,
  SalesAmountName,
  SalesContainerAmount,
  TransferFeeAmount,
  SalesAmountColumns,
  SalesAmountContainer,
  SalesAmount,
  SalesAmountBtnContainer,
}
