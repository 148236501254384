import React, { ReactElement, useMemo } from 'react'
import { NOTIFICATION_TYPES } from '@/constants'
import DefaultUserIcon from '@/assets/img/icons/Login.svg'
import FANME_icon from '@/assets/img/logo/Fanme_app_w_BK.svg'
import Money_icon from '@/assets/img/icons/MoneyManagement.svg'
import Completion_icon from '@/assets/img/icons/Completion.svg'
import Reject_icon from '@/assets/img/icons/Reject.svg'
import { StyledCreatorCoverImg } from '@/components/molecules/MyMenu/NotificationListItem/index.style'
import { Notification } from '../NotificationList/index'

const DEFAULT_ICON_SIZE = 24

const NOTIFICATION_TYPE_ICONS: Record<string, (size: number) => ReactElement> = {
  [NOTIFICATION_TYPES.REJECTED]: size => <Reject_icon height={size} width={size} />,
  [NOTIFICATION_TYPES.MANAGEMENT]: size => <FANME_icon height={size} width={size} />,
  [NOTIFICATION_TYPES.COMPLETED]: size => <Completion_icon height={size} width={size} />,
  [NOTIFICATION_TYPES.MONEY]: size => <Money_icon height={size} width={size} />,
  [NOTIFICATION_TYPES.REQUEST]: size => <FANME_icon height={size} width={size} />,
  [NOTIFICATION_TYPES.GENERAL]: size => <FANME_icon height={size} width={size} />,
  [NOTIFICATION_TYPES.REACTION]: size => <DefaultUserIcon height={size} width={size} />,
  [NOTIFICATION_TYPES.RECEIVE_FAN_LETTER]: size => <DefaultUserIcon height={size} width={size} />,
}

export const useNotificationIcon = (
  notification: Notification,
  iconSize: number = DEFAULT_ICON_SIZE,
): ReactElement => {
  return useMemo(() => {
    if (notification.iconUrl) {
      return (
        <StyledCreatorCoverImg
          src={notification.iconUrl}
          alt="image"
          height={iconSize}
          width={iconSize}
        />
      )
    }
    const defaultNotificationIcon =
      NOTIFICATION_TYPE_ICONS[notification.type] ||
      (size => <FANME_icon height={size} width={size} />)
    return defaultNotificationIcon(iconSize)
  }, [notification, iconSize])
}
