import React from 'react'
import HelpIcon from '@/assets/img/icons/Help.svg'
import { APP_INSTALL_PERMISSION_REQUEST_URL } from '@/constants/apps'
import { StyledMyMenuItem, StyledMyMenuItemName } from '../index.style'

interface Props {
  isFullSize?: boolean
}

const Contact = ({ isFullSize }: Props) => {
  return (
    <StyledMyMenuItem
      width={isFullSize ? '100%' : '152px'}
      flexDirectionRow={isFullSize ? true : false}
      onClick={() => {
        window.open(APP_INSTALL_PERMISSION_REQUEST_URL)
      }}
    >
      <HelpIcon width={20} height={20} />
      <StyledMyMenuItemName>お問い合わせ</StyledMyMenuItemName>
    </StyledMyMenuItem>
  )
}

export default Contact
