import styled from '@emotion/styled'
import { Colors } from '@/constants/styles/color'

const StyledBadgeOuterContainer = styled.div`
  position: relative;
`

const StyledBadgeInnerContainer = styled.div<{ right: number; top: number }>`
  position: absolute;
  right: ${props => props.right}px;
  top: ${props => props.top}px;
`
const StyledBadge = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${Colors.RED};
`

export { StyledBadge, StyledBadgeOuterContainer, StyledBadgeInnerContainer }
