import React from 'react'
import { useFanmeBackendGet } from '@/libs/fanme_backend'
import { StyledBadge } from './index.style'

const BlogArticleBadge = () => {
  const { data: badgeData } = useFanmeBackendGet<any>('/fanme/api/creators/self/article/badge', {
    auth: true,
  })

  if (!badgeData || badgeData.count == 0) return null
  return <StyledBadge />
}
export default BlogArticleBadge
