import styled from '@emotion/styled'
import { Colors } from '@/constants/styles/color'

const StyledDialogHeader = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${Colors.BLACK};
`

const StyledDialogHeaderText = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
`

const StyledCloseIcon = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 24px;
    height: 24px;
  }
`

const StyledArrowBackIcon = styled.div`
  width: 24px;
  height: 24px;
  img {
    width: 24px;
    height: 24px;
  }
`

const StyledDialogBody = styled.div`
  padding: 24px;
  padding-bottom: 96px;
  height: calc(100% - 47px);
  overflow: scroll;
  color: ${Colors.BLACK};
`

const StyledCCImages = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  max-width: 260px;
  margin: 0 auto 24px;
`

const StyledFormItem = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`

const StyledTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 11px;
`

const StyledRequired = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
  color: ${Colors.ORANGE};
  padding: 0px 4px;
  margin-left: 4px;
  margin-right: 8px;
`

const StyledCardNumbers = styled.span<{ isExistErrMsg: boolean }>`
  input {
    font-family: 'Noto Sans JP';
    width: 100%;
    height: 40px;
    margin-top: 8px;
    margin-bottom: ${props => (props.isExistErrMsg ? '0' : '16px')};
    border-radius: 8px;
    border: 0px;
    background: ${Colors.ULTRA_LIGHT_GRAY};
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    display: flex;
    align-items: center;
    padding: 0 12px;
  }
`

const StyledCardName = styled.span<{ isExistErrMsg: boolean }>`
  input {
    font-family: 'Noto Sans JP';
    width: 100%;
    height: 40px;
    margin-top: 8px;
    margin-bottom: ${props => (props.isExistErrMsg ? '0' : '16px')};
    border-radius: 8px;
    border: 0px;
    background: ${Colors.ULTRA_LIGHT_GRAY};
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    display: flex;
    align-items: center;
    padding: 0 12px;
  }
`

const StyledErrMsg = styled.div`
  width: 184px;
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
  display: flex;
  align-items: right;
  color: ${Colors.RED};
  margin: 8px 0;
  text-align: left;
`

const StyledCardExpirationDate = styled.span<{ isExistErrMsg: boolean }>`
  input {
    font-family: 'Noto Sans JP';
    width: 72px;
    height: 40px;
    margin-top: 8px;
    margin-bottom: ${props => (props.isExistErrMsg ? '0' : '16px')};
    border-radius: 8px;
    border: 0px;
    background: ${Colors.ULTRA_LIGHT_GRAY};
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    display: flex;
    align-items: center;
    padding: 0 8px;
  }
`

const StyledCardSecurityCode = styled.div<{ isExistErrMsg: boolean }>`
  display: flex;
  align-items: center;
  margin-top: 8px;
  margin-bottom: ${props => (props.isExistErrMsg ? '0' : '16px')};
  input {
    font-family: 'Noto Sans JP';
    width: 64px;
    height: 40px;
    border-radius: 8px;
    border: 0px;
    background: ${Colors.ULTRA_LIGHT_GRAY};
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    display: flex;
    align-items: center;
    padding: 0 8px;
    margin-right: 10px;
  }
`

const StyledPurchaseButton = styled.button<{ disabled: boolean }>`
  background: ${props => (props.disabled ? Colors.VERY_LIGHT_GRAY : Colors.FANME_YELLOW)};
  border: ${props =>
    props.disabled ? `1px solid  ${Colors.VERY_LIGHT_GRAY}` : `1px solid ${Colors.FANME_YELLOW}`};
  border-radius: 24px;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => (props.disabled ? Colors.WHITE : Colors.BLACK)};
  width: calc(100% - 48px);
  height: 48px;
  margin: 40px auto;
`

const StyledCardInfoText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: ${Colors.PRIMARY_GRAY};
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px 0;
  margin-bottom: 16px;
  border-bottom: 1px solid ${Colors.VERY_LIGHT_GRAY};
`

export {
  StyledDialogHeader,
  StyledDialogHeaderText,
  StyledCloseIcon,
  StyledArrowBackIcon,
  StyledDialogBody,
  StyledCCImages,
  StyledFormItem,
  StyledTitle,
  StyledRequired,
  StyledCardNumbers,
  StyledCardName,
  StyledErrMsg,
  StyledCardExpirationDate,
  StyledCardSecurityCode,
  StyledPurchaseButton,
  StyledCardInfoText,
}
